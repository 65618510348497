import React, {useEffect, useState} from 'react';
import {View, ScrollView, ActivityIndicator} from 'react-native';
import {Divider} from 'react-native-paper';
import _ from 'lodash';
import moment from 'moment';

import sy from '~/styles';
import {
  Text,
  RadioButton,
  Link,
  Pressable,
  RobTaskIcon,
  MoreMenu,
  WarrantyIcon,
  Dialog,
  FormNumberInput,
  Checkbox,
  Quantity,
  PriceCheck,
  ArticleText,
  SlaArticleText,
  ActionButton,
  Grouping,
} from '~/components/controls';
import {Row as GroupingRow} from '~/components/controls/Grouping';
import Actionsheet, {
  Title,
  Content,
  Actions,
} from '~/components/controls/Actionsheet';
import {HOUR_TYPES as DURATION_HOUR_TYPES} from '~/components/shared/Dialogs/duration';
import {
  service_request as serviceRequestApi,
  rob as robApi,
  user as userApi,
} from '~/api/private';
import {
  AddArticleActionsheet,
  AddPartActionsheet,
  RobCodeActionsheet,
  LeniencyActionsheet,
} from '~/components/shared/Actionsheets';
import {
  DiscountDialog,
  MarkupDialog,
  DamageNotAllowedDialog,
  DurationDialog,
} from '~/components/shared/Dialogs';
import Format from '~/lib/format';
import {
  isRobLeniencyTask,
  isRobTaskDenied,
  isRobTaskChanged,
} from '~/types/rob';
import withDimensions from '~/components/hoc/with-dimensions';
import {SOURCES} from '~/types/sources';
import {useDispatch, useSelector} from '~/lib/hooks';
import {setUserSettings} from '~/actions';

import DirectionsCarIcon from '~/images/md-icons/directions_car/materialicons/24px.svg';
import FactoryIcon from '~/images/md-icons/factory/materialicons/24px.svg';
import CreateIcon from '~/images/md-icons/create/materialicons/24px.svg';
import CheckIcon from '~/images/md-icons/check/materialicons/24px.svg';
import HistoryIcon from '~/images/md-icons/history/materialicons/24px.svg';
import HistoryToggleOffIcon from '~/images/md-icons/history_toggle_off/materialicons/24px.svg';
import DiscountIcon from '~/images/md-icons/discount/materialicons/24px.svg';
import AddIcon from '~/images/md-icons/add/materialicons/24px.svg';
import RestartIcon from '~/images/md-icons/restart/materialicons/24px.svg';
import InfoIcon from '~/images/md-icons/info/materialicons/24px.svg';
import LocalOffer from '~/images/md-icons/local_offer/materialicons/24px.svg';

const SCREENS = {
  TASK: 'TASK',
  WORK: 'WORK',
  ROB_CODE: 'ROB_CODE',
};

const ROB_OPERATION_UITVOEREN = '00';
const ROB_REASON_NVT = '99';

const Part = ({
  request_id,
  lessor,
  task_parts,
  sla_parts,
  sla_maintenance_parts,
  onEditArticle,
  hasDiscount,
  partsDiscounts,
  hasMarkup,
  partsMarkups,
  onChangeQuantity,
  onChangeDiscount,
  onChangeMarkup,
}) => (
  <>
    {task_parts.map((item, index) => {
      const sla_part = [
        ...(sla_parts ?? []),
        ...(sla_maintenance_parts ?? []).map((x) => ({
          ...x,
          is_maintenance_part: true,
        })),
      ].find((x) => {
        if (item.gen_art_nr) {
          return x.gen_art_nr === item.gen_art_nr;
        } else if (item.gen_art_nrs) {
          return item.gen_art_nrs.includes(x.gen_art_nr);
        }

        if (x.is_maintenance_part) {
          return item.reference === x.reference;
        }

        return false;
      });

      const itemMpId = item.itemMpId ?? item.attributes.itemMpId;
      const gen_art_nrs = item.gen_art_nr
        ? [parseInt(item.gen_art_nr)]
        : item.gen_arts;
      const quantity = item.quantity ?? item.amounts;
      const description = item.description;
      const price =
        _.isNumber(item.price) && _.isNumber(item.quantity)
          ? item.price * item.quantity
          : null;

      return (
        <React.Fragment key={itemMpId}>
          <Pressable
            style={[sy['flex-row']]}
            onPress={() =>
              onEditArticle({
                itemMpId,
                description,
                gen_art_nrs,
                quantity,
                amounts: item.amounts ?? 1,
                task_part: item,
                sla_part: item.attributes?.option_id
                  ? {
                      id: item.id,
                      description: item.description,
                      price: item.price,
                      lessor: lessor,
                    }
                  : sla_part,
              })
            }>
            <View style={[sy['p-4'], sy['pr-2']]}>
              <Quantity
                quantity={quantity}
                onChange={() => onChangeQuantity(item, itemMpId, quantity)}
              />
            </View>
            <View
              style={[
                sy['flex-row'],
                sy['flex-1'],
                sy['justify-between'],
                sy['py-4'],
                sy['pr-4'],
              ]}>
              <View style={[sy['flex-1']]}>
                <Text>{description}</Text>
                <Text style={[sy.small, sy['text-lightgray'], sy.underline]}>
                  {sla_part ? (
                    <SlaArticleText
                      lessor={lessor}
                      description={sla_part.description}
                      attributes={item.attributes}
                      specifications={item.specifications}
                      request_id={request_id}
                      itemMpId={itemMpId}
                    />
                  ) : _.isNumber(price) ? (
                    <ArticleText source={item.source} number={item.number} />
                  ) : (
                    <>Keuze maken</>
                  )}
                </Text>
              </View>
              <View style={[sy['self-start'], sy['items-end']]}>
                <Pressable
                  disabled={!hasDiscount && !hasMarkup}
                  onPress={() => {
                    if (hasDiscount) {
                      onChangeDiscount(
                        itemMpId,
                        price,
                        partsDiscounts[itemMpId] ?? 0,
                      );
                    }

                    if (hasMarkup) {
                      onChangeMarkup(
                        itemMpId,
                        price,
                        partsMarkups[itemMpId] ?? 0,
                      );
                    }
                  }}>
                  <Text
                    style={[
                      sy.smallRegular,
                      (hasDiscount || hasMarkup) && sy.underline,
                    ]}>
                    {_.isNumber(price) ? (
                      Format.price(
                        Math.max(
                          price +
                            (partsMarkups[itemMpId] ?? 0) -
                            (partsDiscounts[itemMpId] ?? 0),
                          0,
                        ),
                      )
                    ) : (
                      <></>
                    )}
                  </Text>
                </Pressable>
                {partsDiscounts[itemMpId] > 0 && (
                  <DiscountIcon fill="#4A4A49" width={18} height={18} />
                )}
                {partsMarkups[itemMpId] > 0 && (
                  <LocalOffer fill="#4A4A49" width={18} height={18} />
                )}
              </View>
            </View>
          </Pressable>
          {index < task_parts.length - 1 && (
            <Divider style={[sy['bg-gray95'], sy['ml-16']]} />
          )}
        </React.Fragment>
      );
    })}
  </>
);

const History = ({mileage, task_parts, history}) => {
  const item_mp_ids = [
    ...new Set(
      [...(task_parts?.map((item) => item.attributes.itemMpId ?? null) ?? [])]
        .filter(Boolean)
        .map((item) => parseInt(item)),
    ),
  ];

  const parts = item_mp_ids
    .map((item_mp_id) => history?.[item_mp_id])
    .filter(Boolean)
    // Strip location information
    .flatMap((item) => Object.values(item));

  if (parts.length === 0) {
    return (
      <View style={[sy['p-4'], sy['flex-row']]}>
        <Text>Geen historie bekend</Text>
      </View>
    );
  }

  return parts.map((part, index) => (
    <React.Fragment key={index}>
      <View style={[sy['p-4'], sy['flex-row']]}>
        <Quantity quantity={part.quantity} />
        <View style={[sy['flex-1'], sy['flex-row'], sy['justify-between']]}>
          <View style={[sy['px-4'], sy['flex-1']]}>
            <Text style={[sy.truncate]}>{part.description}</Text>
            <Text style={[sy.small, sy['text-lightgray']]}>{part.number}</Text>
          </View>
          {_.isNumber(part.mileage) && _.isNumber(mileage) ? (
            <View style={sy['items-end']}>
              <Text>
                {Format.number(mileage - part.mileage, 0, true)}&nbsp; km
              </Text>
              <Text style={[sy.small, sy['text-lightgray']]}>
                {Format.number(
                  moment().diff(moment(part.date), 'months') / 12,
                  1,
                  true,
                  {
                    minimumFractionDigits: 0,
                  },
                )}
                &nbsp;jaar
              </Text>
            </View>
          ) : (
            <Text>
              {Format.number(
                moment().diff(moment(part.date), 'months') / 12,
                1,
                true,
                {
                  minimumFractionDigits: 0,
                },
              )}
              &nbsp;jaar
            </Text>
          )}
        </View>
      </View>
      {index < parts.length - 1 && (
        <Divider style={[sy['bg-gray95'], sy['ml-18']]} />
      )}
    </React.Fragment>
  ));
};

const Activity = ({disabled, rob_code, description, onPress}) => (
  <GroupingRow
    style={[sy['gap-2']]}
    contentStyle={[sy['flex-1']]}
    title={<Text style={sy.mediumPlus}>Activiteit</Text>}>
    <Link
      disabled={disabled}
      textStyle={[sy.truncate, sy['text-right']]}
      onPress={onPress}>
      {rob_code ? (
        <>
          {rob_code}&nbsp;&middot;&nbsp;
          {description}
        </>
      ) : (
        <>Keuze maken</>
      )}
    </Link>
  </GroupingRow>
);

const HEADER_HEIGHT_IN_PX = 72;
const CAR_INFO_HEIGHT_IN_PX = 58;
const FOOTER_HEIGHT_IN_PX = 52;

const Maintenance = ({
  onDismiss,
  dimensions,
  license,
  is_fleet_car,
  is_retail_car,
  vin_number,
  mileage,
  car,
  request_id,
  dealer_id,
  lessor,
  task,
  additional,
  rob,
  onChange,
  onShowDenialReasons,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [screen, setScreen] = useState(SCREENS.TASK);
  const [hasMaintenance, setHasMaintenance] = useState(false);
  const [maintenance, setMaintenance] = useState([]);
  const [parts, setParts] = useState(null);
  const [codes, setCodes] = useState(null);
  const [codeSuggestions, setCodeSuggestions] = useState(null);
  const [selected, setSelected] = useState({
    ...task,
  });
  const [discounts, setDiscounts] = useState({
    [task.id]: task.discount,
    ...(additional?.length > 0
      ? additional.reduce((acc, item) => {
          acc[item.id] = item.discount;
          return acc;
        }, {})
      : {}),
  });
  const [partsDiscounts, setPartsDiscounts] = useState(
    Object.keys(task.parts ?? {}).length > 0
      ? Object.keys(task.parts).reduce((acc, itemMpId) => {
          acc[itemMpId] = task.parts[itemMpId]?.discount ?? 0;
          return acc;
        }, {})
      : {},
  );
  const [markups, setMarkups] = useState({
    [task.id]: task.markup,
    ...(additional?.length > 0
      ? additional.reduce((acc, item) => {
          acc[item.id] = item.markup;
          return acc;
        }, {})
      : {}),
  });
  const [partsMarkups, setPartsMarkups] = useState(
    Object.keys(task.parts ?? {}).length > 0
      ? Object.keys(task.parts).reduce((acc, itemMpId) => {
          acc[itemMpId] = task.parts[itemMpId]?.markup ?? 0;
          return acc;
        }, {})
      : {},
  );
  const [selectedAdditional, setSelectedAdditional] = useState(
    additional?.map((item) => item.id) ?? [],
  );
  const [selectedWorkId, setSelectedWorkId] = useState(task.reference);
  const [rob_components, setRobComponents] = useState(null);
  const [history, setHistory] = useState(null);
  const [hasHistory, setHasHistory] = useState(null);
  const [hourlyRate, setHourlyRate] = useState(null);
  const [slaParts, setSlaParts] = useState(null);
  const [slaMaintenanceParts, setSlaMaintenanceParts] = useState(null);
  const [editArticle, setEditArticle] = useState(null);
  const [taskAmount, setTaskAmount] = useState(task.task_amount);
  const [priceCheck, setPriceCheck] = useState(null);
  const [hasDiscount, _setHasDiscount] = useState(
    task.discount > 0 ||
      additional?.some((item) => item.discount > 0) ||
      Object.values(task.parts ?? [])
        .filter(Boolean)
        .some((item) => item.discount > 0),
  );
  const [hasMarkup, _setHasMarkup] = useState(
    task.markup > 0 ||
      additional?.some((item) => item.markup > 0) ||
      Object.values(task.parts ?? [])
        .filter(Boolean)
        .some((item) => item.markup > 0),
  );
  const [componentsWithDamageAllowed, setComponentsWithDamageAllowed] =
    useState(true);

  const toggleHasDiscount = () => {
    _setHasDiscount((prevState) => {
      const newState = !prevState;
      if (!newState) {
        setDiscounts({});
        setPartsDiscounts({});
      } else {
        // Reset markup
        setMarkups({});
        setPartsMarkups({});
      }

      return newState;
    });
  };

  const toggleHasMarkup = () => {
    _setHasMarkup((prevState) => {
      const newState = !prevState;
      if (!newState) {
        setMarkups({});
        setPartsMarkups({});
      } else {
        // Reset discount
        setDiscounts({});
        setPartsDiscounts({});
      }

      return newState;
    });
  };

  const [showEditNumber, setShowEditNumber] = useState(null);
  const [showPartsHistory, setShowPartsHistory] = useState(false);
  const [showLeniencyActionsheet, setShowLeniencyActionsheet] = useState(false);
  const [showWarrantyInfoDialog, setShowWarrantyInfoDialog] = useState(false);
  const [showAddPartActionsheet, setShowAddPartActionsheet] = useState(false);
  const [showDiscountDialog, setShowDiscountDialog] = useState(null);
  const [showMarkupDialog, setShowMarkupDialog] = useState(null);
  const [showDuplicateWarning, setShowDuplicateWarning] = useState(null);
  const [showPartsSplitDialog, setShowPartsSplitDialog] = useState(false);
  const [showDamageNotAllowedDialog, setShowDamageNotAllowedDialog] =
    useState(false);
  const [showDurationDialog, setShowDurationDialog] = useState(false);

  const user_settings = useSelector((state) => state.user_settings);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const {result} = await serviceRequestApi.has_maintenance(request_id);
      setHasMaintenance(result?.has_maintenance);
      if (result.has_maintenance) {
        const {result} = await serviceRequestApi.maintenance(request_id);

        setMaintenance(
          Object.values(result.maintenance ?? {})
            .sort((a, b) => a.text.localeCompare(b.text))
            .map((item) => ({
              ...item,
              children: item.children
                ?.map((child) => ({
                  ...child,
                  display_text: child.qualifiers
                    .filter(
                      (q) => !item.qualifiers.map((x) => x.id).includes(q.id),
                    )
                    .map((q) => q.text)
                    .join(', '),
                }))
                .sort((a, b) => a.display_text.localeCompare(b.display_text)),
            })),
        );
        setParts(result.parts);
        setCodes(result.codes);
        setCodeSuggestions(result.suggestions);
      }

      if (!task?.id) {
        if (result?.has_maintenance) {
          setScreen(SCREENS.WORK);
        } else {
          setSelected({
            ...task,
            duration: 0,
          });
          setScreen(SCREENS.TASK);
        }
      }

      if (!is_fleet_car && !is_retail_car) {
        const {result: rob_components} = await robApi.components(
          license,
          request_id,
        );
        setRobComponents(rob_components);
        const {result: rob_contract} = await robApi.contract(
          license,
          request_id,
        );
        setComponentsWithDamageAllowed(
          rob_contract?.supplierContract.repairAndMaintenance
            .componentsWithDamageAllowed,
        );
      }

      const response = await serviceRequestApi.car_history(request_id);
      const {history} = response.result;
      setHistory(history);

      const {result: sla_result} = await serviceRequestApi.sla(request_id);
      setSlaParts(sla_result?.parts);
      setSlaMaintenanceParts(sla_result?.maintenance_parts);

      setLoading(false);
    };

    fetch();
  }, [request_id, license, task, rob, vin_number, lessor]);

  useEffect(() => {
    const fetch = async () => {
      const {result} = await serviceRequestApi.sla_hourly_rate(
        request_id,
        selected.rob_code,
      );
      setHourlyRate(result.hourly_rate);
    };

    fetch();
  }, [request_id, selected?.rob_code]);

  useEffect(() => {
    const fetch = async () => {
      const {result: price_check} = await robApi.task_price_check(
        lessor,
        selected.rob_code,
        vin_number,
        hourlyRate,
      );

      setPriceCheck(price_check);
    };

    if (selected?.rob_code && hourlyRate) {
      fetch();
    }
  }, [selected?.rob_code, hourlyRate, vin_number, lessor]);

  // TODO: Move the sla option part to the API
  useEffect(() => {
    setSelected((prevState) => {
      const task_parts = prevState.parts;
      let changed = false;
      for (const task_part of Object.values(task_parts)) {
        if (task_part?.id || !task_part?.gen_arts?.length) {
          continue;
        }

        const sla_part = slaParts?.find((item) =>
          task_part.gen_arts.includes(parseInt(item.gen_art_nr)),
        );

        if (sla_part) {
          changed = true;

          task_parts[task_part.itemMpId] = {
            ...sla_part,
            description: sla_part.designation,
            quantity: task_part.amounts,
            attributes: {
              itemMpId: task_part.itemMpId,
            },
            specifications: task_part.specifications,
          };

          // SLA part options
          for (const option of sla_part.options ?? []) {
            if (
              option.condition?.rob_operation_code.includes(
                ROB_OPERATION_UITVOEREN,
              )
            ) {
              task_parts[option.id] = {
                gen_art_nr: option.gen_art_nr,
                description: option.item,
                price: option.price,
                quantity: 1,
                attributes: {
                  itemMpId: option.id,
                  option_id: option.id,
                },
              };
            }
          }
        }
      }

      if (changed) {
        return {
          ...prevState,
          parts: {...task_parts},
        };
      }

      return prevState;
    });
  }, [selected, slaParts]);

  useEffect(() => {
    if (!history) {
      return;
    }

    const item_mp_ids = [
      ...new Set([
        ...(parts?.[selected.reference]?.map((item) => item.itemMpId) ?? []),
        ...(Object.values(selected.parts ?? {}).map(
          (item) => item.attributes?.itemMpId,
        ) ?? []),
      ]),
    ];

    setHasHistory(
      Object.keys(history).some((item_mp_id) =>
        item_mp_ids.includes(parseInt(item_mp_id)),
      ),
    );
  }, [selected, history, parts]);

  useEffect(() => {
    let amount;
    if (selected?.warranty === 'Warranty') {
      amount = 0;
    } else if (isRobLeniencyTask(selected?.warranty)) {
      amount = selected?.leniency_amount;
    } else {
      const task_amount = Math.max(
        hourlyRate * selected.duration +
          (markups[task.id] ?? 0) -
          (discounts[task.id] ?? 0),
        0,
      );
      const parts_amount = Object.values(selected.parts ?? {})
        .filter((item) => _.isNumber(item.price) && _.isNumber(item.quantity))
        .reduce(
          (acc, item) =>
            acc +
            Math.max(
              item.price * item.quantity +
                (partsMarkups[item.attributes.itemMpId] ?? 0) -
                (partsDiscounts[item.attributes.itemMpId] ?? 0),
              0,
            ),
          0,
        );

      amount =
        Math.round(task_amount * 100) / 100 +
        Math.round(parts_amount * 100) / 100;

      if (selectedAdditional) {
        amount += selectedAdditional.reduce((acc, item) => {
          const task = additional.find((x) => x.id === item);

          return (
            acc +
            Math.round(
              Math.max(
                task.duration * hourlyRate +
                  (markups[task.id] ?? 0) -
                  (discounts[task.id] ?? 0),
                0,
              ) * 100,
            ) /
              100
          );
        }, 0);
      }
    }

    setTaskAmount(amount);
  }, [
    selected,
    selectedAdditional,
    hourlyRate,
    additional,
    discounts,
    partsDiscounts,
    task,
    markups,
    partsMarkups,
  ]);

  const onAddParts = (parts) => {
    const changes =
      parts?.reduce((acc, {id, ...item}) => {
        acc[id] = {
          ...item,
        };
        return acc;
      }, {}) ?? {};

    setSelected({
      ...selected,
      parts: {...(selected.parts ?? {}), ...changes},
    });

    setShowAddPartActionsheet(false);
  };

  const retrieveChanges = () => {
    let work_id = selected.reference;
    let work_number = selected.number;
    let work_text = selected.work_text;
    let duration = selected.duration;
    let itemMpId = selected.itemMpId;
    let original_work_time = selected.reference
      ? selected.original_work_time
      : null;

    let work = maintenance
      .flatMap((item) => item.children)
      .find((child) => child.id === selected.reference);
    if (work) {
      work_id = work.id;
      work_number = work.number;
      work_text = work.text;
      itemMpId = work.itemMpId;
      original_work_time = work.work_time;
    }

    const changes = {
      duration,
      original_duration: selected.original_duration,
      work_id,
      work_number,
      work_text,
      work_item_mp_id: itemMpId,
      original_work_time,
      rob_code: selected.rob_code,
      description: rob_components?.[selected.rob_code]?.description,
      parts: Object.keys(selected.parts ?? {}).map((itemMpId) => {
        const item = selected.parts[itemMpId];
        const quantity = item.quantity ?? item.amounts ?? 1;
        const gen_art_nr = item.gen_art_nr ?? item.gen_arts?.[0];

        return {
          ...item,
          discount: Math.min(
            partsDiscounts[itemMpId] ?? 0,
            item.price * quantity,
          ),
          markup: partsMarkups[itemMpId] ?? 0,
          quantity,
          gen_art_nr,
          attributes: {
            ...item.attributes,
            itemMpId,
          },
        };
      }),
      split_parts: !!work_id ? selected.split_parts : null,
      operation: ROB_OPERATION_UITVOEREN,
      reason: ROB_REASON_NVT,
      warranty: selected.warranty,
      leniency_amount: isRobLeniencyTask(selected.warranty)
        ? selected.leniency_amount
        : null,
      is_damage: selected.is_damage,
      discount: discounts?.[task.id],
      markup: markups?.[task.id],
      additional: selectedAdditional.map((id) => ({
        id,
        discount: discounts[id],
        markup: markups[id],
      })),
    };

    return changes;
  };

  const onOK = () => {
    const changes = retrieveChanges();
    onChange({
      ...changes,
      task_id: task.id,
    });
  };

  const onDuplicate = async () => {
    const changes = retrieveChanges();
    await onChange({
      ...changes,
      replace_id: rob.id,
      rob_code: showDuplicateWarning.change,
      parts: changes.parts?.map((part) => ({
        ...part,
        id: null,
      })),
    });
  };

  const onResetParts = () => {
    for (const part of parts[selectedWorkId] ?? []) {
      if (!selected.parts[part.itemMpId]) {
        let quantity = part.amounts;
        let description = part.description;

        const sla_part = slaParts.find((item) =>
          part.gen_arts.includes(parseInt(item.gen_art_nr)),
        );

        let price;
        let gen_art_nr = part.gen_arts[0];
        if (sla_part) {
          description = sla_part.description;
          gen_art_nr = sla_part.gen_art_nr;
          price = sla_part.price;
        }

        selected.parts[part.itemMpId] = {
          description,
          quantity,
          price,
          gen_art_nr,
          attributes: {
            itemMpId: part.itemMpId,
          },
        };
      }
    }

    setSelected({
      ...selected,
      parts: {...selected.parts},
    });
  };

  if (showAddPartActionsheet) {
    return (
      <AddPartActionsheet
        request_id={request_id}
        dealer_id={dealer_id}
        license={license}
        parts={Object.values(selected.parts).map((item) => ({
          ...item,
          id: item.itemMpId ?? item.attributes.itemMpId,
        }))}
        lessor={lessor}
        rob_code={selected.rob_code}
        onChange={onAddParts}
        onDismiss={() => setShowAddPartActionsheet(false)}
      />
    );
  }

  if (editArticle) {
    return (
      <AddArticleActionsheet
        dismissable={false}
        onDismiss={() => setEditArticle(null)}
        request_id={request_id}
        license={license}
        dealer_id={dealer_id}
        description={editArticle.description}
        gen_arts={editArticle.gen_art_nrs}
        item_mp_id={editArticle.itemMpId}
        number={editArticle.task_part?.number}
        reference={editArticle.task_part?.reference}
        sla_part={editArticle.sla_part}
        task_part={editArticle.task_part}
        lessor={lessor}
        rob_code={selected.rob_code}
        onChange={({
          reference,
          number,
          source,
          description,
          price,
          quantity,
          gen_art_nr,
          specifications,
        }) => {
          selected.parts[editArticle.itemMpId] = {
            ...selected.parts[editArticle.itemMpId],
            reference,
            number,
            source: source ?? editArticle.task_part?.source,
            description,
            price,
            gen_art_nr,
            quantity:
              _.isNumber(editArticle.task_part?.quantity) &&
              editArticle.task_part?.quantity !== 1
                ? editArticle.task_part?.quantity
                : Math.max(
                    editArticle.task_part?.quantity ?? 0,
                    editArticle.amounts ?? 0,
                    quantity ?? 0,
                    1,
                  ),
            attributes: {
              ...(selected.parts[editArticle.itemMpId].attributes ?? {}),
              itemMpId: editArticle.itemMpId,
            },
            specifications,
          };

          setSelected({
            ...selected,
            parts: {...selected.parts},
          });

          setEditArticle(null);
        }}
        onRemove={() => {
          selected.parts[editArticle.itemMpId] = null;

          setSelected({
            ...selected,
            parts: {..._.pickBy(selected.parts)},
          });

          setEditArticle(null);
        }}
      />
    );
  }

  if (showLeniencyActionsheet) {
    return (
      <LeniencyActionsheet
        onDismiss={() => setShowLeniencyActionsheet(false)}
        value={selected.warranty}
        task_amount={taskAmount}
        onChange={(leniency, leniency_amount) => {
          setSelected({
            ...selected,
            warranty: leniency,
            leniency_amount,
            is_damage: false,
          });
          setShowLeniencyActionsheet(false);
        }}
      />
    );
  }

  if (screen === SCREENS.ROB_CODE) {
    return (
      <RobCodeActionsheet
        request_id={request_id}
        license={license}
        codes={[
          ...new Set([
            selected?.rob_code,
            ...(codes?.[selected?.reference || null] ?? []),
          ]),
        ].filter(Boolean)}
        rob_code={selected?.rob_code}
        options={{maintenance_codes_only: true}}
        onDismiss={() => setScreen(SCREENS.TASK)}
        onChange={(rob_code) => {
          if (
            isRobTaskDenied(rob?.status.code) ||
            isRobTaskChanged(rob?.status.code)
          ) {
            setShowDuplicateWarning({
              rob_code: selected?.rob_code,
              change: rob_code,
            });
          } else {
            setSelected({
              ...selected,
              rob_code,
            });
          }

          setScreen(SCREENS.TASK);
        }}
      />
    );
  }

  return (
    <>
      <Actionsheet visible={true} onDismiss={onDismiss}>
        {screen === SCREENS.TASK && (
          <>
            <Title>
              <View
                style={[
                  sy['flex-row'],
                  sy['justify-between'],
                  sy['items-center'],
                  sy['flex-1'],
                  {
                    width: '100%',
                  },
                ]}>
                <Text style={[sy.largePlus, {lineHeight: 30}]}>Onderhoud</Text>
                <View style={[sy['flex-row'], sy['items-center']]}>
                  {!rob && !!priceCheck && (
                    <PriceCheck
                      rob_code={selected.rob_code}
                      amount={taskAmount}
                      range={priceCheck?.range}
                      duration={selected.duration}
                      suggested_duration={priceCheck?.suggested_duration}
                      onChangeDuration={(suggested, original) =>
                        setSelected({
                          ...selected,
                          duration: suggested,
                          original_duration: _.isNumber(
                            selected.original_work_time,
                          )
                            ? suggested !== selected.original_work_time
                              ? selected.original_work_time
                              : null
                            : original,
                        })
                      }
                    />
                  )}
                  {rob?.status.code && (
                    <Pressable
                      style={[sy['flex-row']]}
                      onPress={() => onShowDenialReasons()}>
                      <RobTaskIcon
                        statuses={[rob?.status.code]}
                        height="24"
                        width="24"
                      />
                    </Pressable>
                  )}
                </View>
              </View>
            </Title>
            <Divider />

            {loading && (
              <View style={[sy['p-4']]}>
                <ActivityIndicator size="large" color="#231fda" />
              </View>
            )}
            {!loading && (
              <>
                <Content style={{paddingBottom: 0, paddingHorizontal: 0}}>
                  <ScrollView
                    contentContainerStyle={{
                      maxHeight:
                        dimensions.window.height -
                        HEADER_HEIGHT_IN_PX -
                        FOOTER_HEIGHT_IN_PX,
                    }}>
                    <View style={[sy['flex-row'], sy['p-4']]}>
                      <View style={[sy['pr-4']]}>
                        {selected.reference ? <FactoryIcon /> : <CreateIcon />}
                      </View>
                      <View style={[sy['flex-1']]}>
                        <Pressable
                          style={[sy['pl-2']]}
                          onPress={() => setScreen(SCREENS.WORK)}>
                          <Text>Arbeidstijd</Text>
                          <Text
                            style={[
                              sy.underline,
                              sy.truncate,
                              sy.small,
                              sy['text-lightgray'],
                            ]}>
                            {_.isNumber(selected.duration) ? (
                              <>
                                {selected.reference ? (
                                  <>
                                    TecRMI&nbsp;&middot;&nbsp;
                                    {selected.work_text}
                                  </>
                                ) : (
                                  <>Invoer</>
                                )}
                              </>
                            ) : (
                              <>Keuze maken</>
                            )}
                          </Text>
                        </Pressable>
                      </View>
                      <View>
                        <View
                          style={[
                            sy['flex-row'],
                            sy['gap-2'],
                            sy['justify-end'],
                          ]}>
                          {markups[task.id] > 0 ? (
                            <LocalOffer fill="#4A4A49" width={18} height={18} />
                          ) : discounts[task.id] > 0 ? (
                            <DiscountIcon
                              fill="#4A4A49"
                              width={18}
                              height={18}
                            />
                          ) : null}
                          {_.isNumber(selected.duration) && (
                            <Pressable
                              disabled={!hasDiscount && !hasMarkup}
                              onPress={() => {
                                if (hasDiscount) {
                                  setShowDiscountDialog({
                                    discount: discounts[task.id],
                                    amount: selected.duration * hourlyRate,
                                    onChange: (discount) => {
                                      setDiscounts({
                                        ...(discounts ?? {}),
                                        [task.id]: discount,
                                      });
                                    },
                                  });
                                }

                                if (hasMarkup) {
                                  setShowMarkupDialog({
                                    markup: markups[task.id],
                                    amount: selected.duration * hourlyRate,
                                    onChange: (markup) => {
                                      setMarkups({
                                        ...(markups ?? {}),
                                        [task.id]: markup,
                                      });
                                    },
                                  });
                                }
                              }}>
                              <Text
                                style={[
                                  sy.smallRegular,
                                  (hasDiscount || hasMarkup) && sy.underline,
                                ]}>
                                {Format.price(
                                  Math.max(
                                    selected.duration * hourlyRate +
                                      (markups[task.id] ?? 0) -
                                      (discounts[task.id] ?? 0),
                                    0,
                                  ),
                                )}
                              </Text>
                            </Pressable>
                          )}
                        </View>
                        <View
                          style={[
                            sy['flex-row'],
                            sy['gap-2'],
                            sy['justify-end'],
                          ]}>
                          {selected.original_duration > 0 ? (
                            <Text
                              style={[
                                sy.smallRegular,
                                sy['text-lightgray'],
                                sy['line-through'],
                              ]}>
                              {Format.number(selected.original_duration, 2)}
                              &nbsp;u
                            </Text>
                          ) : null}
                          <Pressable
                            onPress={() => setShowDurationDialog(true)}>
                            <Text
                              style={[
                                sy.smallRegular,
                                sy['text-lightgray'],
                                sy.underline,
                              ]}>
                              {Format.number(selected.duration, 2)}&nbsp;u
                            </Text>
                          </Pressable>
                        </View>
                      </View>
                    </View>
                    {_.isNumber(selected.duration) && (
                      <>
                        {additional?.map((item) => (
                          <View
                            key={item.id}
                            style={[sy['flex-row'], sy['p-4']]}>
                            <View style={[sy['pr-4']]}>
                              <Checkbox
                                checked={selectedAdditional.includes(item.id)}
                                disabledTimeout={0}
                                onPress={() => {
                                  if (selectedAdditional.includes(item.id)) {
                                    setSelectedAdditional(
                                      selectedAdditional.filter(
                                        (x) => x !== item.id,
                                      ),
                                    );
                                  } else {
                                    setSelectedAdditional([
                                      ...selectedAdditional,
                                      item.id,
                                    ]);
                                  }
                                }}
                              />
                            </View>
                            <View
                              style={[sy['flex-1'], sy['pl-4']]}
                              onPress={() => setScreen(SCREENS.WORK)}>
                              <Text>{item.work_text ?? item.description}</Text>
                            </View>
                            <View
                              style={[
                                sy['flex-row'],
                                sy['gap-2'],
                                sy['justify-end'],
                              ]}>
                              {markups[item.id] > 0 ? (
                                <LocalOffer
                                  fill="#4A4A49"
                                  width={18}
                                  height={18}
                                />
                              ) : discounts[item.id] > 0 ? (
                                <DiscountIcon
                                  fill="#4A4A49"
                                  width={18}
                                  height={18}
                                />
                              ) : null}
                              <Pressable
                                disabled={!hasDiscount && !hasMarkup}
                                onPress={() => {
                                  if (hasDiscount) {
                                    setShowDiscountDialog({
                                      discount: discounts[item.id],
                                      amount: item.duration * hourlyRate,
                                      onChange: (discount) => {
                                        setDiscounts({
                                          ...(discounts ?? {}),
                                          [item.id]: discount,
                                        });
                                      },
                                    });
                                  }

                                  if (hasMarkup) {
                                    setShowMarkupDialog({
                                      markup: markups[item.id],
                                      amount: item.duration * hourlyRate,
                                      onChange: (markup) => {
                                        setMarkups({
                                          ...(markups ?? {}),
                                          [item.id]: markup,
                                        });
                                      },
                                    });
                                  }
                                }}>
                                <Text
                                  style={[
                                    sy.smallRegular,
                                    (hasDiscount || hasMarkup) && sy.underline,
                                  ]}>
                                  {Format.price(
                                    Math.max(
                                      item.duration * hourlyRate -
                                        (markups[item.id] ?? 0) -
                                        (discounts[item.id] ?? 0),
                                      0,
                                    ),
                                  )}
                                </Text>
                              </Pressable>
                              <View>
                                <View
                                  style={[
                                    sy['flex-row'],
                                    sy['gap-2'],
                                    sy['justify-end'],
                                  ]}>
                                  {_.isNumber(item.calculated_work_time) && (
                                    <Text
                                      style={[
                                        sy.smallRegular,
                                        sy['text-lightgray'],
                                        sy['line-through'],
                                      ]}>
                                      &nbsp;(
                                      {Format.number(
                                        item.original_work_time,
                                        2,
                                      )}
                                      &nbsp;u)
                                    </Text>
                                  )}
                                  <Text
                                    style={[
                                      sy.smallRegular,
                                      sy['text-lightgray'],
                                    ]}>
                                    {Format.number(item.duration, 2)}
                                    &nbsp;u
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                        ))}
                        <Divider />
                        <View
                          style={[
                            sy['p-4'],
                            sy['flex-row'],
                            sy['justify-between'],
                          ]}>
                          <Text style={sy.mediumPlus}>Artikel(en)</Text>
                          {Object.values(selected.parts ?? {}).length > 0 && (
                            <Pressable
                              onPress={() =>
                                setShowPartsHistory(!showPartsHistory)
                              }>
                              {hasHistory && (
                                <>
                                  {showPartsHistory ? (
                                    <HistoryToggleOffIcon />
                                  ) : (
                                    <HistoryIcon />
                                  )}
                                </>
                              )}
                            </Pressable>
                          )}
                        </View>
                        {Object.values(selected.parts ?? {}).length > 0 ? (
                          <>
                            <Divider />
                            <>
                              {hasHistory && showPartsHistory ? (
                                <History
                                  mileage={mileage}
                                  task_parts={Object.values(selected.parts)}
                                  history={history}
                                />
                              ) : (
                                <Part
                                  request_id={request_id}
                                  lessor={lessor}
                                  task_parts={Object.values(selected.parts)}
                                  sla_parts={slaParts}
                                  sla_maintenance_parts={slaMaintenanceParts}
                                  onEditArticle={setEditArticle}
                                  hasDiscount={hasDiscount}
                                  partsDiscounts={partsDiscounts}
                                  hasMarkup={hasMarkup}
                                  partsMarkups={partsMarkups}
                                  onChangeQuantity={(
                                    task_part,
                                    itemMpId,
                                    amounts,
                                  ) => {
                                    setShowEditNumber({
                                      title: 'Hoeveelheid',
                                      label: 'Aantal',
                                      value: task_part?.quantity ?? amounts,
                                      onChange: (value) => {
                                        selected.parts[itemMpId] = {
                                          ...selected.parts[itemMpId],
                                          quantity: value,
                                        };

                                        setSelected({
                                          ...selected,
                                          parts: {...selected.parts},
                                        });
                                      },
                                    });
                                  }}
                                  onChangeDiscount={(
                                    itemMpId,
                                    amount,
                                    discount,
                                  ) => {
                                    setShowDiscountDialog({
                                      discount,
                                      amount,
                                      onChange: (value) => {
                                        setPartsDiscounts({
                                          ...partsDiscounts,
                                          [itemMpId]: value,
                                        });
                                      },
                                    });
                                  }}
                                  onChangeMarkup={(
                                    itemMpId,
                                    amount,
                                    markup,
                                  ) => {
                                    setShowMarkupDialog({
                                      markup,
                                      amount,
                                      onChange: (value) => {
                                        setPartsMarkups({
                                          ...partsMarkups,
                                          [itemMpId]: value,
                                        });
                                      },
                                    });
                                  }}
                                />
                              )}
                            </>
                            <Divider style={[sy['bg-gray95'], sy['ml-16']]} />
                          </>
                        ) : (
                          <Divider />
                        )}
                        <View style={[sy['flex-row']]}>
                          <Pressable
                            style={[sy['flex-row'], sy['flex-1']]}
                            onPress={() => setShowAddPartActionsheet(true)}>
                            <View style={[sy['p-4']]}>
                              <AddIcon fill="#231fda" />
                            </View>
                            <View style={[sy['flex-1']]}>
                              <View style={[sy['p-4'], sy['pl-2']]}>
                                <Text>Artikel toevoegen</Text>
                              </View>
                            </View>
                          </Pressable>
                          {parts?.[selectedWorkId]?.some(
                            (part) => !selected.parts[part.itemMpId],
                          ) && (
                            <Pressable
                              style={[sy['p-4']]}
                              onPress={onResetParts}>
                              <RestartIcon width={24} height={24} />
                            </Pressable>
                          )}
                        </View>
                        {rob_components && (
                          <Grouping header="ROB-Net" initial={{expand: true}}>
                            <Activity
                              disabled={
                                rob?.id &&
                                !isRobTaskDenied(rob?.status.code) &&
                                !isRobTaskChanged(rob?.status.code)
                              }
                              rob_code={selected.rob_code}
                              description={
                                rob_components[selected?.rob_code]?.description
                              }
                              onPress={() => {
                                setScreen(SCREENS.ROB_CODE);
                              }}
                            />
                          </Grouping>
                        )}
                      </>
                    )}
                  </ScrollView>
                </Content>
                <Divider />
                <Actions style={[sy['justify-between']]}>
                  <View style={[sy['flex-row'], sy['items-center']]}>
                    <Text style={[sy.mediumBold, sy['pl-2'], sy['pr-4']]}>
                      {Format.price(taskAmount || 0)}
                    </Text>
                    <MoreMenu
                      icon={
                        <View style={[sy['flex-row'], sy['items-center']]}>
                          {hasDiscount && <DiscountIcon fill="#4A4A49" />}
                          {hasMarkup && <LocalOffer fill="#4A4A49" />}
                          {((!hasDiscount && !hasMarkup) ||
                            selected.warranty ||
                            selected.is_damage) && (
                            <WarrantyIcon
                              value={{
                                warranty: selected.warranty,
                                is_damage: selected.is_damage,
                              }}
                            />
                          )}
                        </View>
                      }
                      items={[
                        {
                          title: 'Korting',
                          onPress: () => {
                            if (hasMarkup) {
                              toggleHasMarkup();
                            }

                            toggleHasDiscount();
                            setSelected((prevState) => ({
                              ...prevState,
                              warranty: null,
                              leniency_amount: null,
                              is_damage: false,
                            }));
                          },
                          leadingIcon: hasDiscount ? CheckIcon : null,
                        },
                        {
                          title: 'Toeslag',
                          onPress: () => {
                            if (hasDiscount) {
                              toggleHasDiscount();
                            }

                            toggleHasMarkup();
                            setSelected((prevState) => ({
                              ...prevState,
                              warranty: null,
                              leniency_amount: null,
                              is_damage: false,
                            }));
                          },
                          leadingIcon: hasMarkup ? CheckIcon : null,
                        },
                        {
                          title: 'Garantie',
                          onPress: () => {
                            if (hasDiscount) {
                              toggleHasDiscount();
                            }

                            if (hasMarkup) {
                              toggleHasMarkup();
                            }

                            if (selected.warranty !== 'Warranty') {
                              setSelected({
                                ...selected,
                                warranty: 'Warranty',
                                leniency_amount: null,
                                is_damage: false,
                              });
                              setShowWarrantyInfoDialog(true);
                            } else {
                              setSelected({
                                ...selected,
                                warranty: null,
                                leniency_amount: null,
                                is_damage: false,
                              });
                            }
                          },
                          leadingIcon:
                            selected.warranty === 'Warranty' ? CheckIcon : null,
                        },
                        {
                          title: 'Coulance',
                          leadingIcon: isRobLeniencyTask(selected.warranty)
                            ? CheckIcon
                            : null,
                          onPress: () => {
                            if (hasDiscount) {
                              toggleHasDiscount();
                            }

                            if (hasMarkup) {
                              toggleHasMarkup();
                            }

                            if (!isRobLeniencyTask(selected.warranty)) {
                              setTimeout(
                                () => setShowLeniencyActionsheet(true),
                                100,
                              );
                            } else {
                              setSelected({
                                ...selected,
                                warranty: null,
                                leniency_amount: null,
                                is_damage: false,
                              });
                            }
                          },
                        },
                        {
                          title: (
                            <>
                              {componentsWithDamageAllowed ? (
                                'Schade'
                              ) : (
                                <View style={[sy['flex-row'], sy['gap-4']]}>
                                  <Text>Schade</Text>
                                  <InfoIcon
                                    fill="#4A4A49"
                                    height={24}
                                    width={24}
                                  />
                                </View>
                              )}
                            </>
                          ),
                          onPress: () => {
                            if (componentsWithDamageAllowed) {
                              if (hasDiscount) {
                                toggleHasDiscount();
                              }

                              if (hasMarkup) {
                                toggleHasMarkup();
                              }

                              if (selected.is_damage) {
                                setSelected({
                                  ...selected,
                                  warranty: null,
                                  leniency_amount: null,
                                  is_damage: false,
                                });
                              } else {
                                setSelected({
                                  ...selected,
                                  warranty: null,
                                  leniency_amount: null,
                                  is_damage: true,
                                });
                              }
                            } else {
                              setShowDamageNotAllowedDialog(true);
                            }
                          },
                          leadingIcon: selected.is_damage ? CheckIcon : null,
                        },
                      ]}
                    />
                  </View>
                  <View style={[sy['flex-row'], sy['gap-2']]}>
                    <ActionButton onPress={onDismiss}>Annuleren</ActionButton>
                    <ActionButton onPress={onOK}>OK</ActionButton>
                  </View>
                </Actions>
              </>
            )}
          </>
        )}
        {screen === SCREENS.WORK && (
          <>
            <Title>
              <View
                style={[
                  sy['flex-row'],
                  sy['justify-between'],
                  sy['items-center'],
                  sy['flex-1'],
                  {
                    width: '100%',
                  },
                ]}>
                <Text style={[sy.largePlus, {lineHeight: 30}]}>
                  Standaard service
                </Text>
              </View>
            </Title>
            <Divider />

            {loading && (
              <View style={[sy['p-4']]}>
                <ActivityIndicator size="large" color="#231fda" />
              </View>
            )}
            {!loading && (
              <>
                <Content style={{paddingBottom: 0, paddingHorizontal: 0}}>
                  <View>
                    <View
                      style={[sy['flex-row'], sy['items-center'], sy['p-4']]}>
                      <View style={[sy['pr-4']]}>
                        <DirectionsCarIcon />
                      </View>
                      <View style={[sy['flex-1'], sy['flex-row']]}>
                        {_.isNumber(mileage) && (
                          <Text style={[sy.mediumBold]}>
                            {Format.number(mileage, 0, true)}
                            &nbsp;km&nbsp;&middot;&nbsp;
                          </Text>
                        )}
                        <Text style={[sy.mediumBold]}>
                          {Format.number(
                            moment().diff(car.entry_date, 'months') / 12,
                            1,
                            true,
                            {
                              minimumFractionDigits: 0,
                            },
                          )}
                          &nbsp;jaar
                        </Text>
                      </View>
                    </View>
                    <Divider />
                    <ScrollView
                      contentContainerStyle={{
                        maxHeight:
                          dimensions.window.height -
                          (HEADER_HEIGHT_IN_PX + CAR_INFO_HEIGHT_IN_PX) -
                          FOOTER_HEIGHT_IN_PX,
                      }}>
                      {hasMaintenance && maintenance && (
                        <>
                          <View
                            style={[
                              sy['flex-row'],
                              sy['items-center'],
                              sy['p-4'],
                            ]}>
                            <View style={[sy['pr-4']]}>
                              <RadioButton
                                checked={!!selectedWorkId}
                                onPress={() => {
                                  if (!selectedWorkId) {
                                    setSelectedWorkId(
                                      maintenance[0]?.children[0].id,
                                    );
                                  }
                                }}
                              />
                            </View>
                            <View style={[sy['flex-row'], sy['flex-1']]}>
                              <Text style={[sy['flex-1']]}>
                                TecRMI onderhoud
                              </Text>
                              <View>
                                <FactoryIcon />
                              </View>
                            </View>
                          </View>
                          <View style={[sy['pl-4']]}>
                            {Object.values(maintenance).map((item) => {
                              const work_selected = item.children
                                .map((item) => item.id)
                                .includes(selectedWorkId);

                              return (
                                <React.Fragment key={item.id}>
                                  <View
                                    style={[
                                      sy['flex-row'],
                                      sy['items-start'],
                                      sy['p-4'],
                                      sy['pl-6'],
                                    ]}>
                                    <View style={[sy['pr-4']]}>
                                      <RadioButton
                                        checked={work_selected}
                                        onPress={() => {
                                          setSelectedWorkId(
                                            item.children[0].id,
                                          );
                                        }}
                                      />
                                    </View>
                                    <View style={[sy['flex-1']]}>
                                      <Text>
                                        {item.text}
                                        {item.children.length === 1 &&
                                          parts[item.children[0].id] && (
                                            <>
                                              &nbsp;(
                                              {
                                                parts[item.children[0].id]
                                                  .length
                                              }
                                              )
                                            </>
                                          )}
                                      </Text>
                                      {item.qualifiers.length > 0 && (
                                        <Text
                                          style={[
                                            sy.smallRegular,
                                            sy['text-lightgray'],
                                          ]}>
                                          {item.qualifiers
                                            .map((q) => q.text)
                                            .join(', ')}
                                        </Text>
                                      )}
                                    </View>
                                    {item.children.length === 1 && (
                                      <Text>
                                        {Format.number(
                                          item.children[0].work_time,
                                          2,
                                        )}
                                        &nbsp;u
                                      </Text>
                                    )}
                                  </View>
                                  {work_selected &&
                                    item.children.length > 1 &&
                                    item.children.map((child) => (
                                      <View
                                        key={child.id}
                                        style={[
                                          sy['flex-row'],
                                          sy['items-start'],
                                          sy['p-4'],
                                          sy['pl-12'],
                                        ]}>
                                        <View style={[sy['pr-4']]}>
                                          <RadioButton
                                            checked={
                                              selectedWorkId === child.id
                                            }
                                            onPress={() =>
                                              setSelectedWorkId(child.id)
                                            }
                                          />
                                        </View>
                                        <View style={[sy['flex-1']]}>
                                          <Text>
                                            {child.display_text}
                                            {parts[child.id] && (
                                              <>
                                                &nbsp;(
                                                {parts[child.id].length})
                                              </>
                                            )}
                                          </Text>
                                        </View>
                                        <Text>
                                          {Format.number(child.work_time, 2)}
                                          &nbsp;u
                                        </Text>
                                      </View>
                                    ))}
                                </React.Fragment>
                              );
                            })}
                          </View>
                        </>
                      )}
                      <View
                        style={[sy['flex-row'], sy['items-center'], sy['p-4']]}>
                        <View style={[sy['pr-4']]}>
                          <RadioButton
                            checked={!selectedWorkId}
                            onPress={() => {
                              setSelectedWorkId(null);
                            }}
                          />
                        </View>
                        <View style={[sy['flex-row'], sy['flex-1']]}>
                          <Text style={[sy['flex-1']]}>Invoer</Text>
                          <CreateIcon />
                        </View>
                      </View>
                    </ScrollView>
                  </View>
                </Content>
                <Divider />
                <Actions style={[sy['justify-between']]}>
                  <View />
                  <View style={[sy['flex-row'], sy['gap-2']]}>
                    <ActionButton
                      onPress={() => {
                        setSelectedWorkId(selected.reference);
                        setScreen(SCREENS.TASK);
                      }}>
                      Annuleren
                    </ActionButton>
                    <ActionButton
                      onPress={() => {
                        if (parseInt(selected.reference) === selectedWorkId) {
                          // No changes
                          setScreen(SCREENS.TASK);
                          return;
                        }

                        const work = maintenance
                          .flatMap((item) => item.children)
                          .find((item) => item.id === selectedWorkId);

                        let split_parts = [];
                        let rob_code = selected.rob_code;
                        let task_parts = {
                          ...selected.parts,
                        };
                        if (work) {
                          task_parts = Object.fromEntries(
                            Object.entries(selected.parts ?? {}).filter(
                              ([k, v]) =>
                                v.source === SOURCES.Fource ||
                                v.source === SOURCES.PartsPoint,
                            ),
                          );

                          for (const part of parts[work.id] ?? []) {
                            if (!task_parts[part.itemMpId]) {
                              if (is_fleet_car || is_retail_car) {
                                // Intentionally left blank
                              } else if (part.rob_code?.length > 0) {
                                split_parts.push(part);
                                continue;
                              }

                              task_parts[part.itemMpId] = part;
                            }
                          }

                          if (!rob) {
                            rob_code = codeSuggestions[work.id];
                          }
                        }

                        if (
                          split_parts.length > 0 &&
                          user_settings.never_show_parts_split_dialog !== true
                        ) {
                          setShowPartsSplitDialog(true);
                        }

                        if (slaMaintenanceParts?.length > 0) {
                          for (const part of slaMaintenanceParts) {
                            task_parts[part.itemMpId] = part;
                          }
                        }

                        setSelected({
                          ...selected,
                          reference: work?.id,
                          work_text: work?.text,
                          duration: work ? work.work_time : 0,
                          original_work_time: work ? work.work_time : null,
                          original_duration: null,
                          itemMpId: work?.itemMpId,
                          rob_code,
                          parts: task_parts,
                          split_parts,
                        });
                        setScreen(SCREENS.TASK);
                      }}>
                      OK
                    </ActionButton>
                  </View>
                </Actions>
              </>
            )}
          </>
        )}
      </Actionsheet>
      {showDurationDialog && (
        <DurationDialog
          priceCheck={priceCheck}
          work_time={selected.reference ? selected.original_work_time : null}
          duration={selected.duration}
          onChange={(duration, type) => {
            switch (type) {
              case DURATION_HOUR_TYPES.suggested: {
                const suggested_duration = priceCheck.suggested_duration;
                if (_.isNumber(selected.original_work_time)) {
                  setSelected({
                    ...selected,
                    duration: suggested_duration,
                    original_duration:
                      suggested_duration !== selected.original_work_time
                        ? selected.original_work_time
                        : null,
                  });
                } else if (suggested_duration !== selected.duration) {
                  setSelected({
                    ...selected,
                    duration: suggested_duration,
                    original_duration: selected.duration,
                  });
                }

                break;
              }

              case DURATION_HOUR_TYPES.work: {
                setSelected({
                  ...selected,
                  duration: selected.original_work_time,
                  original_duration: null,
                });
                break;
              }

              case DURATION_HOUR_TYPES.custom: {
                setSelected({
                  ...selected,
                  duration,
                  original_duration:
                    _.isNumber(selected.original_work_time) &&
                    duration !== selected.original_work_time
                      ? selected.original_work_time
                      : null,
                });
                break;
              }

              default: {
                break;
              }
            }
          }}
          onDismiss={() => setShowDurationDialog(false)}
        />
      )}
      {showEditNumber && (
        <Dialog
          visible={true}
          title={showEditNumber.title}
          initialValue={showEditNumber.value}
          onDismiss={({initialValue}) => {
            showEditNumber.onChange(initialValue);
            setShowEditNumber(null);
          }}
          buttons={[
            {
              text: 'Annuleren',
              onPress: ({initialValue}) => {
                showEditNumber.onChange(initialValue);
                setShowEditNumber(null);
              },
            },
            {
              text: 'OK',
              onPress: () => setShowEditNumber(null),
            },
          ]}>
          <View style={[sy['py-4']]}>
            <FormNumberInput
              label={showEditNumber.label}
              value={showEditNumber.value}
              digits={2}
              onChangeNumber={showEditNumber.onChange}
            />
          </View>
        </Dialog>
      )}
      {showWarrantyInfoDialog && (
        <Dialog
          visible={true}
          title={task.description}
          onDismiss={() => setShowWarrantyInfoDialog(false)}
          buttons={[
            {
              text: 'OK',
              onPress: () => setShowWarrantyInfoDialog(false),
            },
          ]}
          options={{noPaddingContent: true}}>
          <View style={[sy['flex-row']]}>
            <View style={[sy['p-4'], {flexShrink: 0}]}>
              <WarrantyIcon
                value={{warranty: 'Warranty'}}
                width="24"
                height="24"
              />
            </View>
            <Text style={[sy['py-4']]}>
              Garantie toegepast. Bedrag voor activiteit aangepast naar 0,00
              euro.
            </Text>
          </View>
        </Dialog>
      )}
      {showDiscountDialog && (
        <DiscountDialog
          value={showDiscountDialog.discount}
          amount={showDiscountDialog.amount}
          onDismiss={() => setShowDiscountDialog(null)}
          onChange={showDiscountDialog.onChange}
        />
      )}
      {showMarkupDialog && (
        <MarkupDialog
          value={showMarkupDialog.markup}
          amount={showMarkupDialog.amount}
          onDismiss={() => setShowMarkupDialog(null)}
          onChange={showMarkupDialog.onChange}
        />
      )}
      {showDuplicateWarning && (
        <Dialog
          visible={true}
          onDismiss={() => setShowDuplicateWarning(null)}
          buttons={[
            {text: 'Annuleren', onPress: () => setShowDuplicateWarning(null)},
            {
              text: 'Vervangen',
              onPress: () => onDuplicate(),
            },
          ]}>
          <View style={[sy['py-4']]}>
            <Text>Vervangen door {showDuplicateWarning.change}?</Text>
            <Text>({showDuplicateWarning.rob_code} naar vervallen)</Text>
          </View>
        </Dialog>
      )}
      {showPartsSplitDialog && (
        <Dialog
          visible={true}
          onDismiss={() => setShowPartsSplitDialog(false)}
          buttons={[
            {
              text: 'OK',
              onPress: async () => {
                if (user_settings.never_show_parts_split_dialog) {
                  await userApi.update_settings({
                    ...user_settings,
                    never_show_parts_split_dialog: true,
                  });
                }

                setShowPartsSplitDialog(false);
              },
            },
          ]}
          options={{noPaddingContent: true}}
          title="Apart toegevoegd"
          titleIcon={<InfoIcon fill="#4a4a49" />}>
          <View style={[sy['py-4'], sy['px-6']]}>
            <Text>
              Onderdelen in gekozen service met eigen ROB activiteit worden
              apart toegevoegd aan werkopdracht.
            </Text>
          </View>
          <Divider />
          <View style={[sy['py-4'], sy['px-6']]}>
            <View style={[sy['flex-row'], sy['gap-4']]}>
              <Checkbox
                disabledTimeout={0}
                onPress={(value) =>
                  dispatch(
                    setUserSettings({
                      never_show_parts_split_dialog: value,
                    }),
                  )
                }
              />
              <Text>Niet meer tonen</Text>
            </View>
          </View>
        </Dialog>
      )}
      {showDamageNotAllowedDialog && (
        <DamageNotAllowedDialog
          onDismiss={() => setShowDamageNotAllowedDialog(false)}
        />
      )}
    </>
  );
};

export default withDimensions(Maintenance);
