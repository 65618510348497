import React, {useEffect, useState} from 'react';
import {View, ActivityIndicator} from 'react-native';
import {Divider} from 'react-native-paper';

import sy from '~/styles';
import {
  Text,
  Pressable,
  Dialog,
  FormNumberInput,
  RobTaskIcon,
  MoreMenu,
  WarrantyIcon,
  ActionButton,
  Grouping,
} from '~/components/controls';
import {Row as GroupingRow} from '~/components/controls/Grouping';
import Actionsheet, {
  Title,
  Content,
  Actions,
} from '~/components/controls/Actionsheet';
import {LeniencyActionsheet} from '~/components/shared/Actionsheets';
import {
  DiscountDialog,
  MarkupDialog,
  DamageNotAllowedDialog,
} from '~/components/shared/Dialogs';
import {
  service_request as serviceRequestApi,
  rob as robApi,
} from '~/api/private';
import Format from '~/lib/format';
import {isRobLeniencyTask} from '~/types/rob';

import HandshakeIcon from '~/images/md-icons/handshake/materialicons/24px.svg';
import DeleteIcon from '~/images/md-icons/delete/materialicons/24px.svg';
import CheckIcon from '~/images/md-icons/check/materialicons/24px.svg';
import DiscountIcon from '~/images/md-icons/discount/materialicons/24px.svg';
import LocalOfferIcon from '~/images/md-icons/local_offer/materialicons/24px.svg';
import InfoIcon from '~/images/md-icons/info/materialicons/24px.svg';

const TaskAmount = ({warranty, task, parts, discount, markup, style}) => {
  let amount;
  if (warranty?.value === 'Warranty') {
    amount = 0;
  } else if (isRobLeniencyTask(warranty?.value)) {
    amount = warranty?.leniency_amount;
  } else {
    amount =
      task?.amount +
      parts?.reduce((acc, part) => acc + part.price * part.quantity, 0) +
      markup -
      discount;
  }

  return <Text style={style}>{Format.price(amount)}</Text>;
};

const SLA = ({
  onDismiss,
  license,
  is_fleet_car,
  is_retail_car,
  request_id,
  task_id,
  rob,
  onChange,
  onDelete,
  onShowDenialReasons,
}) => {
  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState(null);
  const [parts, setParts] = useState(null);
  const [rob_components, setRobComponents] = useState(null);
  const [showEditNumber, setShowEditNumber] = useState(null);
  const [warranty, setWarranty] = useState(null);
  const [taskDiscount, setTaskDiscount] = useState(0);
  const [hasDiscount, _setHasDiscount] = useState(false);
  const [taskMarkup, setTaskMarkup] = useState(0);
  const [hasMarkup, _setHasMarkup] = useState(false);
  const toggleHasDiscount = () => {
    _setHasDiscount((prevState) => {
      const newState = !prevState;
      if (!newState) {
        setTaskDiscount(0);
      } else {
        // Reset markup
        setTaskMarkup(0);
      }

      return newState;
    });
  };
  const [componentsWithDamageAllowed, setComponentsWithDamageAllowed] =
    useState(true);

  const toggleHasMarkup = () => {
    _setHasMarkup((prevState) => {
      const newState = !prevState;
      if (!newState) {
        setTaskMarkup(0);
      } else {
        // Reset discount
        setTaskDiscount(0);
      }

      return newState;
    });
  };

  const [showLeniencyActionsheet, setShowLeniencyActionsheet] = useState(false);
  const [showWarrantyInfoDialog, setShowWarrantyInfoDialog] = useState(false);
  const [showDiscountDialog, setShowDiscountDialog] = useState(null);
  const [showMarkupDialog, setShowMarkupDialog] = useState(null);
  const [showDamageNotAllowedDialog, setShowDamageNotAllowedDialog] =
    useState(false);

  useEffect(() => {
    const fetch = async () => {
      const {result} = await serviceRequestApi.task(request_id, task_id);

      const {task, parts} = result;
      setTask(task);
      setParts(parts);
      setTaskDiscount(task.discount);
      _setHasDiscount(task.discount > 0);
      setTaskMarkup(task.markup);
      _setHasMarkup(task.markup > 0);

      const task_amount =
        task?.amount +
        parts?.reduce((acc, part) => acc + part.price * part.quantity, 0);
      setWarranty({
        value: task.warranty,
        leniency_amount: task.leniency_amount ?? task_amount,
        is_damage: task.is_damage,
      });

      if (!is_fleet_car && !is_retail_car) {
        const {result: rob_components} = await robApi.components(
          license,
          request_id,
        );
        setRobComponents(rob_components);

        const {result: rob_contract} = await robApi.contract(
          license,
          request_id,
        );
        setComponentsWithDamageAllowed(
          rob_contract?.supplierContract.repairAndMaintenance
            .componentsWithDamageAllowed,
        );
      }

      setLoading(false);
    };

    fetch();
  }, [request_id, license, task_id]);

  const onOK = () => {
    onChange({
      parts,
      warranty: warranty.value,
      leniency_amount: isRobLeniencyTask(warranty.value)
        ? warranty.leniency_amount
        : null,
      is_damage: warranty.is_damage,
      discount: taskDiscount,
      markup: taskMarkup,
    });
  };

  let amount;
  if (warranty?.value === 'Warranty') {
    amount = 0;
  } else if (isRobLeniencyTask(warranty?.value)) {
    amount = warranty?.leniency_amount;
  } else {
    amount = task?.amount;
  }

  if (showLeniencyActionsheet) {
    const task_amount =
      task?.amount +
      parts?.reduce((acc, part) => acc + part.price * part.quantity, 0);
    return (
      <LeniencyActionsheet
        onDismiss={() => setShowLeniencyActionsheet(false)}
        value={warranty.value}
        task_amount={task?.leniency_amount ?? task_amount}
        onChange={(leniency, leniency_amount) => {
          setWarranty({
            ...warranty,
            value: leniency,
            leniency_amount,
            is_damage: false,
          });
          setShowLeniencyActionsheet(false);
        }}
      />
    );
  }

  return (
    <>
      <Actionsheet visible={true} onDismiss={onDismiss}>
        {loading && (
          <View style={[sy['p-4']]}>
            <ActivityIndicator size="large" color="#231fda" />
          </View>
        )}
        {!loading && (
          <>
            <Title style={[sy['mx-4']]}>
              <View
                style={[
                  sy['flex-row'],
                  sy['justify-between'],
                  sy['items-center'],
                  sy['flex-1'],
                  {
                    width: '100%',
                  },
                ]}>
                <View
                  style={[sy['flex-1'], sy['flex-row'], sy['items-center']]}>
                  <Text style={[sy.truncate, sy.largePlus, {lineHeight: 30}]}>
                    {task.description}
                  </Text>
                </View>
                {onDelete && (
                  <Pressable onPress={() => onDelete(task.id)}>
                    <DeleteIcon />
                  </Pressable>
                )}
                {rob?.status.code && (
                  <Pressable
                    style={[sy['flex-row']]}
                    onPress={() => onShowDenialReasons()}>
                    <RobTaskIcon
                      statuses={[rob?.status.code]}
                      height="24"
                      width="24"
                    />
                  </Pressable>
                )}
              </View>
            </Title>
            <Divider />

            <Content style={{paddingBottom: 0, paddingHorizontal: 0}}>
              <View style={[sy['flex-row'], sy['p-4'], sy['gap-8']]}>
                <HandshakeIcon />
                <View>
                  <Text>Vaste prijs</Text>
                  <Text style={[sy.small, sy['text-lightgray']]}>
                    SLA&nbsp;&middot;&nbsp;{task.lessor}
                  </Text>
                </View>
                <View
                  style={[
                    sy['flex-1'],
                    sy['flex-row'],
                    sy['justify-end'],
                    sy['gap-2'],
                  ]}>
                  {taskDiscount > 0 && (
                    <DiscountIcon fill="#4A4A49" width={18} height={18} />
                  )}
                  {taskMarkup > 0 && (
                    <LocalOfferIcon fill="#4A4A49" width={18} height={18} />
                  )}
                  <Pressable
                    disabled={!hasDiscount && !hasMarkup}
                    onPress={() => {
                      if (hasDiscount) {
                        setShowDiscountDialog({
                          discount: taskDiscount,
                          amount,
                          onChange: setTaskDiscount,
                        });
                      }

                      if (hasMarkup) {
                        setShowMarkupDialog({
                          markup: taskMarkup,
                          amount,
                          onChange: setTaskMarkup,
                        });
                      }
                    }}>
                    <Text
                      style={[
                        sy.smallRegular,
                        (hasDiscount || hasMarkup) && sy.underline,
                      ]}>
                      {Format.price(amount + taskMarkup - taskDiscount)}
                    </Text>
                  </Pressable>
                </View>
              </View>
              {parts?.length > 0 && (
                <>
                  <Divider />
                  <View style={[sy['p-4']]}>
                    <Text style={[sy.mediumBold]}>Artikel(en)</Text>
                  </View>
                  {parts?.map((part, index) => (
                    <React.Fragment key={index}>
                      <Divider />
                      <View style={[sy['p-4'], sy['flex-row'], sy['gap-8']]}>
                        <View>
                          <Pressable
                            style={[
                              {
                                minWidth: 24,
                                minHeight: 24,
                                borderRadius: 2,
                              },
                              sy['bg-gray95'],
                              sy['p-1'],
                              sy['items-center'],
                            ]}
                            onPress={() =>
                              setShowEditNumber({
                                title: part.description,
                                value: part.quantity,
                                onChange: (value) => {
                                  parts[index] = {
                                    ...part,
                                    quantity: value,
                                  };

                                  setParts([...parts]);
                                },
                              })
                            }>
                            <Text style={sy.xSmallMedium}>
                              {Format.number(part.quantity, 2, true, {
                                minimumFractionDigits: 0,
                              })}
                            </Text>
                          </Pressable>
                        </View>
                        <View style={[sy['flex-1'], sy['flex-row']]}>
                          <View style={[sy['flex-1']]}>
                            <Text>{part.description}</Text>
                            <Text style={[sy.small, sy['text-lightgray']]}>
                              SLA&nbsp;&middot;&nbsp;{task.lessor}
                            </Text>
                          </View>
                          <View style={[sy['items-end']]}>
                            <Text style={[sy.smallRegular]}>
                              {Format.price(part.price)}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </React.Fragment>
                  ))}
                </>
              )}
              {!is_fleet_car && !is_retail_car && (
                <Grouping header="ROB-Net" initial={{expand: true}}>
                  <GroupingRow
                    title={<Text style={sy.mediumPlus}>Activiteit</Text>}>
                    <Text>
                      {task.rob_code}&nbsp;&middot;&nbsp;
                      {rob_components[task.rob_code]?.description}
                    </Text>
                  </GroupingRow>
                </Grouping>
              )}
            </Content>
            <Divider />
            <Actions style={[sy['justify-between']]}>
              <View style={[sy['flex-row'], sy['items-center']]}>
                <TaskAmount
                  warranty={warranty}
                  task={task}
                  parts={parts}
                  discount={taskDiscount}
                  markup={taskMarkup}
                  style={[sy.mediumBold, sy['pl-2'], sy['pr-4']]}
                />
                <MoreMenu
                  icon={
                    <View style={[sy['flex-row'], sy['items-center']]}>
                      {hasDiscount && <DiscountIcon fill="#4A4A49" />}
                      {hasMarkup && <LocalOfferIcon fill="#4A4A49" />}
                      {((!hasDiscount && !hasMarkup) ||
                        warranty?.value ||
                        warranty?.is_damage) && (
                        <WarrantyIcon
                          value={{
                            warranty: warranty?.value,
                            is_damage: warranty?.is_damage,
                          }}
                        />
                      )}
                    </View>
                  }
                  items={[
                    {
                      title: 'Korting',
                      onPress: () => {
                        if (hasMarkup) {
                          toggleHasMarkup();
                        }

                        toggleHasDiscount();
                        setWarranty({
                          value: null,
                          leniency_amount: null,
                          is_damage: false,
                        });
                      },
                      leadingIcon: hasDiscount ? CheckIcon : null,
                    },
                    {
                      title: 'Toeslag',
                      onPress: () => {
                        if (hasDiscount) {
                          toggleHasDiscount();
                        }

                        toggleHasMarkup();
                        setWarranty({
                          value: null,
                          leniency_amount: null,
                          is_damage: false,
                        });
                      },
                      leadingIcon: hasMarkup ? CheckIcon : null,
                    },
                    {
                      title: 'Garantie',
                      onPress: () => {
                        if (hasDiscount) {
                          toggleHasDiscount();
                        }

                        if (hasMarkup) {
                          toggleHasMarkup();
                        }

                        if (warranty.value !== 'Warranty') {
                          setWarranty({
                            value: 'Warranty',
                            leniency_amount: null,
                            is_damage: false,
                          });

                          setShowWarrantyInfoDialog(true);
                        } else {
                          setWarranty({
                            value: null,
                            leniency_amount: null,
                            is_damage: false,
                          });
                        }
                      },
                      leadingIcon:
                        warranty.value === 'Warranty' ? CheckIcon : null,
                    },
                    {
                      title: 'Coulance',
                      leadingIcon: isRobLeniencyTask(warranty.value)
                        ? CheckIcon
                        : null,
                      onPress: () => {
                        if (hasDiscount) {
                          toggleHasDiscount();
                        }

                        if (hasMarkup) {
                          toggleHasMarkup();
                        }

                        if (!isRobLeniencyTask(warranty.value)) {
                          setTimeout(
                            () => setShowLeniencyActionsheet(true),
                            100,
                          );
                        } else {
                          setWarranty({
                            value: null,
                            leniency_amount: null,
                            is_damage: false,
                          });
                        }
                      },
                    },
                    {
                      title: (
                        <>
                          {componentsWithDamageAllowed ? (
                            'Schade'
                          ) : (
                            <View style={[sy['flex-row'], sy['gap-4']]}>
                              <Text>Schade</Text>
                              <InfoIcon fill="#4A4A49" height={24} width={24} />
                            </View>
                          )}
                        </>
                      ),
                      onPress: () => {
                        if (componentsWithDamageAllowed) {
                          if (hasDiscount) {
                            toggleHasDiscount();
                          }

                          if (hasMarkup) {
                            toggleHasMarkup();
                          }

                          setWarranty((prevState) => ({
                            value: null,
                            leniency_amount: null,
                            is_damage: !prevState.is_damage,
                          }));
                        } else {
                          setShowDamageNotAllowedDialog(true);
                        }
                      },
                      leadingIcon: warranty.is_damage ? CheckIcon : null,
                    },
                  ]}
                />
              </View>
              <View style={[sy['flex-row'], sy['gap-2']]}>
                <ActionButton onPress={onDismiss}>Annuleren</ActionButton>
                <ActionButton onPress={onOK}>OK</ActionButton>
              </View>
            </Actions>
          </>
        )}
      </Actionsheet>
      {showEditNumber && (
        <Dialog
          visible={true}
          title={showEditNumber.title}
          initialValue={showEditNumber.value}
          onDismiss={({initialValue}) => {
            showEditNumber.onChange(initialValue);
            setShowEditNumber(null);
          }}
          buttons={[
            {
              text: 'Annuleren',
              onPress: ({initialValue}) => {
                showEditNumber.onChange(initialValue);
                setShowEditNumber(null);
              },
            },
            {
              text: 'OK',
              onPress: () => setShowEditNumber(null),
            },
          ]}>
          <View style={[sy['py-4']]}>
            <FormNumberInput
              label="Aantal"
              value={showEditNumber.value}
              digits={2}
              onChangeNumber={showEditNumber.onChange}
            />
          </View>
        </Dialog>
      )}
      {showWarrantyInfoDialog && (
        <Dialog
          visible={true}
          title={task.description}
          onDismiss={() => setShowWarrantyInfoDialog(false)}
          buttons={[
            {
              text: 'OK',
              onPress: () => setShowWarrantyInfoDialog(false),
            },
          ]}
          options={{noPaddingContent: true}}>
          <View style={[sy['flex-row']]}>
            <View style={[sy['p-4'], {flexShrink: 0}]}>
              <WarrantyIcon
                value={{warranty: 'Warranty'}}
                width="24"
                height="24"
              />
            </View>
            <Text style={[sy['py-4']]}>
              Garantie toegepast. Bedrag voor activiteit aangepast naar 0,00
              euro.
            </Text>
          </View>
        </Dialog>
      )}
      {showDiscountDialog && (
        <DiscountDialog
          value={showDiscountDialog.discount}
          amount={showDiscountDialog.amount}
          onDismiss={() => setShowDiscountDialog(null)}
          onChange={showDiscountDialog.onChange}
        />
      )}
      {showMarkupDialog && (
        <MarkupDialog
          value={showMarkupDialog.markup}
          amount={showMarkupDialog.amount}
          onDismiss={() => setShowMarkupDialog(null)}
          onChange={showMarkupDialog.onChange}
        />
      )}
      {showDamageNotAllowedDialog && (
        <DamageNotAllowedDialog
          onDismiss={() => setShowDamageNotAllowedDialog(false)}
        />
      )}
    </>
  );
};

export default SLA;
