import React, {useContext} from 'react';
import {View, ScrollView} from 'react-native';
import {Divider} from 'react-native-paper';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import config from '~/configuration';
import sy from '~/styles';

import {useDispatch} from '~/lib/hooks';
import {
  setShowDrawer,
  showLogoutDialog,
  showSettingsModal,
  showHelpModal,
} from '~/actions';
import {
  Text,
  Pressable,
  Checkbox,
  PrimaryButton,
  Drawer,
} from '~/components/controls';
import {
  DrawerHeader,
  DrawerTitle,
  DrawerSubtitle,
  DrawerContent,
} from '~/components/controls/Drawer';
import {user as userApi} from '~/api/private';
import UserContext from '~/components/context/UserContext';
import withDimensions from '~/components/hoc/with-dimensions';

import CheckboxIcon from '../../../images/md-icons/check_box/materialicons/24px.svg';
import CheckboxOutlineIcon from '../../../images/md-icons/check_box_outline_blank/materialicons/24px.svg';
import IndeterminateCheckboxIcon from '~/images/md-icons/indeterminate_check_box/materialicons/24px.svg';
import SettingsIcon from '~/images/md-icons/settings/materialicons/24px.svg';
import LogoutIcon from '~/images/md-icons/logout_white/materialicons/18px.svg';
import FeedbackIcon from '~/images/md-icons/feedback/materialicons/24px.svg';
import LightbulbIcon from '~/images/md-icons/lightbulb/materialicons/24px.svg';
import OpenInNewIcon from '~/images/md-icons/open_in_new/materialicons/24px.svg';

const SideDrawer = ({dimensions}) => {
  const dispatch = useDispatch();
  const {top} = useSafeAreaInsets();

  const {me, update} = useContext(UserContext);

  const {width} = dimensions.window;

  return (
    <Drawer
      onClose={() => dispatch(setShowDrawer(false))}
      style={{
        width: Math.max(Math.min(width - 56, 356), 256),
        paddingBottom: top,
      }}>
      <DrawerHeader style={{paddingBottom: 16}}>
        <DrawerTitle>
          <Text style={sy.largePlus}>
            {me?.first_name} {me?.last_name}
          </Text>
        </DrawerTitle>
        <DrawerSubtitle>
          <Text style={[sy.smallPlus, {color: '#828282'}]}>{me?.email}</Text>
        </DrawerSubtitle>
      </DrawerHeader>
      <DrawerContent>
        <Divider style={sy['bg-gray95']} />
        <ScrollView>
          <View style={[sy['p-4'], sy['gap-6']]}>
            <Text style={[sy.smallPlus, {color: '#828282'}]}>Vestiging</Text>
            <View style={[sy['gap-6']]}>
              {me?.dealers?.length > 1 && (
                <Pressable
                  onPress={async () => {
                    let changes = [];
                    if (me.dealers.length !== me.dealer_selection.length) {
                      changes = me.dealers.map(({id}) => id);
                    }

                    await userApi.update({dealer_selection: changes});
                    await update();
                  }}
                  style={[sy['flex-row'], sy['items-center'], sy['gap-8']]}>
                  {me.dealer_selection.length === me.dealers.length ? (
                    <CheckboxIcon fill="#231fda" />
                  ) : me.dealer_selection.length === 0 ? (
                    <CheckboxOutlineIcon fill="#4a4a49" />
                  ) : (
                    <IndeterminateCheckboxIcon fill="#231fda" />
                  )}

                  <Text>Alle</Text>
                </Pressable>
              )}
              {me?.dealers
                ?.sort((a, b) => a.city.localeCompare(b.city))
                .map((dealer) => (
                  <Pressable
                    key={dealer.id}
                    style={[sy['flex-row'], sy['items-center'], sy['gap-8']]}
                    onPress={async () => {
                      let changes = me?.dealer_selection;
                      if (changes.includes(dealer.id)) {
                        changes = changes.filter((item) => item !== dealer.id);
                      } else {
                        changes.push(dealer.id);
                      }

                      await userApi.update({dealer_selection: changes});
                      await update();
                    }}>
                    <Checkbox
                      checked={me?.dealer_selection?.includes(dealer.id)}
                    />
                    <Text>{dealer.city}</Text>
                  </Pressable>
                ))}
            </View>
          </View>
        </ScrollView>
        <Divider style={sy['bg-gray95']} />
        <View style={[sy['p-4']]}>
          <Pressable
            onPress={() => {
              dispatch(showSettingsModal());
            }}
            style={[sy['flex-row'], sy['items-center'], sy['gap-8']]}>
            <SettingsIcon fill="#4a4a49" />
            <Text>Instellingen</Text>
          </Pressable>
        </View>
        <View style={[sy['p-4']]}>
          <Pressable
            onPress={() => {
              dispatch(showHelpModal());
            }}
            style={[sy['flex-row'], sy['items-center'], sy['gap-8']]}>
            <FeedbackIcon fill="#4a4a49" />
            <Text>Help & feedback</Text>
          </Pressable>
        </View>
        <View style={[sy['p-4']]}>
          <a
            style={sy['no-underline']}
            href={config.whatsnewUrl}
            target="_blank"
            rel="noreferrer">
            <View style={[sy['flex-row'], sy['justify-between']]}>
              <View style={[sy['flex-row'], sy['items-center'], sy['gap-8']]}>
                <LightbulbIcon fill="#4a4a49" />
                <Text>Nieuw</Text>
              </View>
              <OpenInNewIcon fill="#4a4a49" />
            </View>
          </a>
        </View>
      </DrawerContent>
      <View style={[sy['p-4'], sy['justify-center'], sy['items-center']]}>
        <View style={{width: 160}}>
          <PrimaryButton
            icon={<LogoutIcon />}
            onPress={() => {
              dispatch(setShowDrawer(false));
              dispatch(showLogoutDialog());
            }}>
            Uitloggen
          </PrimaryButton>
        </View>
      </View>
    </Drawer>
  );
};

export default withDimensions(SideDrawer);
