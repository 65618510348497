import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useRef,
} from 'react';
import {View, ScrollView, TextInput, Animated} from 'react-native';
import {ActivityIndicator, Divider} from 'react-native-paper';
import {HeaderBackButton} from '@react-navigation/elements';
import _ from 'lodash';
import moment from 'moment';

import sy from '~/styles';
import withProtectedScreen from '~/components/hoc/with-protected-screen';
import {
  Text,
  Checkbox,
  RadioButton,
  Pressable,
  Link,
  PrimaryButton,
  Grouping,
  Dialog,
  KpiWarning,
} from '~/components/controls';
import Format from '~/lib/format';
import {
  service_request as serviceRequestApi,
  rob as robApi,
  car as carApi,
  fleet as fleetApi,
} from '~/api/private';
import {SERVICE_TYPE_COMPONENTS, SERVICE_TYPE_CODES} from '~/types/services';
import {setAppIsLoading, setAppDoneLoading, showTecrmiDialog} from '~/actions';
import {useDispatch} from '~/lib/hooks';
import {RobCodeActionsheet} from '~/components/shared/Actionsheets';
import {
  isLessorStatus,
  TIRE_PERMISSION_LABELS,
  TIRE_PERMISSION_SORT,
} from '~/types/rob';
import {STATUS_CODES} from '~/types/statuses';
import {SOURCES} from '~/types/sources';
import {REQUEST_TYPES} from '~/types/request';

import DirectionsCarIcon from '~/images/md-icons/directions_car/materialicons/24px.svg';
import CloseIcon from '~/images/md-icons/close/materialicons/24px.svg';
import SearchIcon from '~/images/md-icons/search/materialicons/24px.svg';
import CarRepairIcon from '~/images/md-icons/car_repair/materialicons/24px.svg';
import ApprovalIcon from '~/images/md-icons/approval/materialicons/24px.svg';
import TireRepairIcon from '~/images/md-icons/tire_repair/materialicons/24px.svg';
import WarningIcon from '~/images/md-icons/warning/materialicons/24px.svg';
import PriorityHighIcon from '~/images/md-icons/priority_high/materialicons/24px.svg';
import InfoIcon from '~/images/md-icons/info/materialicons/24px.svg';
import UnfoldMoreIcon from '~/images/md-icons/unfold_more/materialicons/24px.svg';

const INSPECTION_EXTENDED_MONTHS = {
  'Bahnkick LM': 8,
  'Ayvens (vh LeasePlan Ned. NV)': 8,
  Alphabet: 6,
};
const INSPECTION_EXTENDED_LM = [
  'Bahnkick LM',
  'Ayvens (vh LeasePlan Ned. NV)',
  'Alphabet',
];

const HighlightText = ({text, highlight}) => {
  const position = text.toLowerCase().indexOf(highlight.toLowerCase());

  return (
    <>
      {position === -1 ? (
        <Text>{text}</Text>
      ) : (
        <>
          {position > 0 && <Text>{text.substring(0, position)}</Text>}
          <Text style={sy.mediumBold}>
            {text.substring(position, position + highlight.length)}
          </Text>
          {position + highlight.length < text.length && (
            <Text>{text.substring(position + highlight.length)}</Text>
          )}
        </>
      )}
    </>
  );
};

const ServiceComponent = ({
  request_id,
  type,
  datetime,
  is_retail_car,
  contract,
  tecrmi_enabled,
  components,
  checked,
  onCheck,
  onRefresh,
}) => {
  const dispatch = useDispatch();
  const [showInspectionInfoDialog, setShowInspectionInfoDialog] =
    useState(false);

  let disabled =
    !is_retail_car && contract?.permissions.repairAndMaintenance !== true;
  let subtitle = null;
  let icon = null;
  let hasPriority = false;
  let hasInspectionInfo = false;
  switch (type) {
    case SERVICE_TYPE_CODES.Maintenance: {
      if (disabled) {
        subtitle = 'Niet in contract';
      } else {
        if (
          contract?.last_service_request?.maintenance ||
          contract?.vehicle?.maintenance?.lastMaintenanceDate
        ) {
          let date;
          let code;

          const last_bk_maintenance_date = contract?.last_service_request
            ?.maintenance?.date
            ? moment(contract?.last_service_request?.maintenance?.date)
            : null;
          const last_rob_maintenance_date = contract?.vehicle?.maintenance
            ?.lastMaintenanceDate
            ? moment(contract?.vehicle?.maintenance?.lastMaintenanceDate)
            : null;

          if (
            !last_rob_maintenance_date ||
            last_bk_maintenance_date?.isSameOrAfter(
              last_rob_maintenance_date,
              'date',
            )
          ) {
            date = last_bk_maintenance_date;
            code = contract?.last_service_request?.maintenance?.code;
          } else {
            date = last_rob_maintenance_date;
            code = contract.leaseContract
              ? `(${contract.vehicle.maintenance.lastMaintenanceCode})`
              : '';
          }

          subtitle = `Laatst bekend ${moment(date).format('DD-MM-YYYY')} ${
            code ? `(${code})` : ''
          }`;
        }
      }

      icon = <CarRepairIcon fill="#828282" />;
      break;
    }

    case SERVICE_TYPE_CODES.Inspection: {
      if (disabled) {
        subtitle = 'Niet in contract';
      } else {
        subtitle = contract?.vehicle?.maintenance?.nextPeriodicCheck
          ? `Vervalt ${moment(
              contract?.vehicle?.maintenance?.nextPeriodicCheck,
            ).format('DD-MM-YYYY')}`
          : null;

        hasPriority =
          contract?.vehicle?.maintenance?.nextPeriodicCheck &&
          moment(datetime)
            .add(2, 'months')
            .isAfter(moment(contract?.vehicle?.maintenance?.nextPeriodicCheck));

        hasInspectionInfo =
          !hasPriority &&
          contract?.vehicle?.maintenance?.nextPeriodicCheck &&
          INSPECTION_EXTENDED_LM.includes(contract?.lessor?.name) &&
          moment().isAfter(
            moment(contract?.vehicle?.maintenance?.nextPeriodicCheck).add(
              -(INSPECTION_EXTENDED_MONTHS[contract?.lessor?.name] ?? 8),
              'months',
            ),
          );
      }

      icon = <ApprovalIcon fill="#828282" />;
      break;
    }

    case SERVICE_TYPE_CODES.Tires: {
      const allowed = [];
      const denied = [];

      Object.keys(
        is_retail_car
          ? {swap: true, replace: true, repair: true}
          : contract?.permissions.tires ?? {},
      )
        .filter((item) => TIRE_PERMISSION_LABELS[item])
        .sort(
          (a, b) =>
            TIRE_PERMISSION_SORT.indexOf(a) - TIRE_PERMISSION_SORT.indexOf(b),
        )
        .forEach((item) => {
          if (is_retail_car || contract.permissions.tires[item]) {
            allowed.push(TIRE_PERMISSION_LABELS[item]);
          } else {
            denied.push(TIRE_PERMISSION_LABELS[item]);
          }
        });

      if (!denied.length) {
        subtitle = allowed.join(', ');
      } else if (!allowed.length) {
        subtitle = `${denied.join(', ')} niet in contract`;
      } else {
        subtitle = `${allowed.join(', ')} (${denied.join(
          ', ',
        )} niet in contract)`;
      }

      disabled = allowed.length === 0;
      subtitle = subtitle.charAt(0).toUpperCase() + subtitle.slice(1);

      icon = <TireRepairIcon fill="#828282" />;
      break;
    }

    default:
      break;
  }

  if (!disabled) {
    const request_component = components.find(
      (item) => item.component_name === SERVICE_TYPE_COMPONENTS[type],
    );

    if (request_component) {
      // If it's checked than disable the possibility to delete this component.
      disabled = true;
    }
  }

  return (
    <View style={[sy['flex-row'], sy['p-4'], sy['gap-4']]}>
      <Pressable
        disabled={disabled}
        onPress={async () => {
          if (
            !is_retail_car &&
            type === SERVICE_TYPE_CODES.Maintenance &&
            !tecrmi_enabled > 0
          ) {
            try {
              await new Promise((resolve, reject) => {
                dispatch(
                  showTecrmiDialog({
                    resolve,
                    reject,
                  }),
                );
              });

              await serviceRequestApi.enable_tecrmi(request_id);
              await onRefresh();
            } catch {
              return;
            }
          }

          onCheck(SERVICE_TYPE_COMPONENTS[type], type);
        }}>
        <Checkbox
          style={[sy['justify-start']]}
          checked={checked}
          disabled={disabled}
          disabledTimeout={0}
        />
      </Pressable>
      <View style={[sy['flex-row'], sy['flex-1'], sy['justify-between']]}>
        <View>
          <Text>{SERVICE_TYPE_COMPONENTS[type]}</Text>
          <View style={[sy['flex-row'], sy['gap-2']]}>
            <Text style={[sy.small, sy['text-lightgray']]}>{subtitle}</Text>
            {hasPriority && (
              <PriorityHighIcon fill="#972727" width={18} height={18} />
            )}
            {hasInspectionInfo && (
              <Pressable onPress={() => setShowInspectionInfoDialog(true)}>
                <InfoIcon fill="#FF6C00" width={18} height={18} />
              </Pressable>
            )}
          </View>
        </View>
        {icon}
      </View>

      <Dialog
        visible={showInspectionInfoDialog}
        title="APK bij onderhoud"
        titleIcon={<InfoIcon fill="#FF6C00" />}
        onDismiss={() => setShowInspectionInfoDialog(false)}>
        <View style={[sy['flex-row'], sy['py-4']]}>
          <Text>
            Verzoek LM: APK combineren met onderhoud bij vervaldatum binnen{' '}
            {INSPECTION_EXTENDED_MONTHS[contract?.lessor?.name] ?? 8} maanden.
          </Text>
        </View>
      </Dialog>
    </View>
  );
};

const ChangeRobCodeLink = ({
  rob,
  task_id,
  rob_code,
  rob_description,
  work_id,
  custom_codes,
  onPress,
}) => {
  let code = rob_code;
  let description = rob_description;
  if (custom_codes[work_id]) {
    ({code, description} = custom_codes[work_id]);
  }

  if (!code) {
    return (
      <Link
        textStyle={[sy.small, sy['text-lightgray']]}
        onPress={() => onPress(work_id, null)}>
        Keuze maken
      </Link>
    );
  }

  let disabled = false;
  if (task_id && rob?.components[task_id]) {
    disabled = true;
  }

  return (
    <Link
      disabled={disabled}
      textStyle={[sy.small, sy['text-lightgray']]}
      onPress={() => onPress(work_id, code)}>
      {code}&nbsp;&middot;&nbsp;{description}
    </Link>
  );
};

const doSearch = _.debounce(
  async (
    value,
    request_id,
    tecrmi_enabled,
    is_fleet_car,
    is_retail_car,
    setResults,
    setSearching,
  ) => {
    if (value.length < 3) {
      setResults([]);
      return;
    }

    setSearching(true);
    const results =
      is_fleet_car || is_retail_car
        ? {
            [SOURCES.SlaTasks]: [],
            [SOURCES.TecRMI]: [],
          }
        : {
            [SOURCES.Rob]: [],
            [SOURCES.TecRMI]: [],
          };

    if (is_fleet_car || is_retail_car) {
      const {result} = await serviceRequestApi.search_sla_tasks(
        request_id,
        value,
      );

      if (result.length > 0) {
        results[SOURCES.SlaTasks] = result.map((result) => ({
          ...result,
          source: SOURCES.SlaTasks,
        }));
      }
    } else {
      const {result} = await serviceRequestApi.search_rob_code(
        request_id,
        value,
        {
          include_tasks: true,
        },
      );

      if (result.length > 0) {
        results[SOURCES.Rob] = result.map((result) => ({
          ...result,
          source: SOURCES.Rob,
        }));
      }
    }

    if (tecrmi_enabled) {
      const {result} = await serviceRequestApi.search_works(request_id, value, {
        search_rob_codes: true,
      });

      if (result.length > 0) {
        results[SOURCES.TecRMI] = result.map((result) => ({
          ...result,
          source: SOURCES.TecRMI,
        }));
      }
    }

    setResults(results);
    setSearching(false);
  },
  500,
);

const Changes = ({selected, hasChanges, onAdd}) => {
  const count =
    selected.components.length +
    Object.keys(selected.codes).length +
    Object.keys(selected.works).length +
    Object.keys(selected.extras).length;

  return (
    <PrimaryButton disabled={!hasChanges()} onPress={onAdd}>
      Toevoegen{count > 0 ? <>&nbsp;({count})</> : <></>}
    </PrimaryButton>
  );
};

const Index = ({navigation, route}) => {
  const request_id = route.params?.request_id;
  const license = route.params?.license;
  const [loading, setLoading] = useState(true);
  const [datetime, setDatetime] = useState(null);
  const [mileage, setMileage] = useState(null);
  const [car, setCar] = useState(null);
  const [tecrmiEnabled, setTecRMIEnabled] = useState(false);
  const [components, setComponents] = useState([]);
  const [search, setSearch] = useState(false);
  const [results, setResults] = useState([]);
  const [suggestions, setSuggestions] = useState(null);
  const [slaCodes, setSlaCodes] = useState([]);
  const [mostUsedCodes, setMostUsedCodes] = useState([]);
  const [slaKpis, setSlaKpis] = useState({});
  const [customCodes, setCustomCodes] = useState({});
  const [contract, setContract] = useState(null);
  const [rob, setRob] = useState(null);
  const [existing, setExisting] = useState({
    components: [],
    codes: {},
    works: {},
    extras: {},
  });
  const [selected, setSelected] = useState({
    components: [],
    codes: {},
    works: {},
    extras: {},
  });
  const [is_fleet_car, setIsFleetCar] = useState(false);
  const [is_retail_car, setIsRetailCar] = useState(false);

  const [searching, setSearching] = useState(false);
  const [highlighSearchText, setHighlightSearchText] = useState('');
  const searchTextRef = useRef(null);

  const [showRobCodeActionsheet, setShowRobCodeActionsheet] = useState(null);
  const [showContractEndsDialog, setShowContractEndsDialog] = useState(null);
  const [showCancelAddDialog, setShowCancelAddDialog] = useState(null);

  const [showAllTasks, setShowAllTasks] = useState(false);

  const dispatch = useDispatch();

  const navigateBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.replace('Request', {
        request_id,
      });
    }
  }, [navigation, request_id]);

  const cancelAnim = useRef(new Animated.Value(0)).current;

  const onPrefixChange = useCallback(
    (value) => {
      if (value?.length === 1) {
        Animated.timing(cancelAnim, {
          toValue: 100,
          duration: 250,
          useNativeDriver: true,
        }).start();
        setSearch(true);
      }

      if (value?.length === 0) {
        Animated.timing(cancelAnim, {
          toValue: 0,
          duration: 0,
          useNativeDriver: true,
        }).start();
        setSearch(false);
      }

      doSearch.cancel();
      doSearch(
        value,
        request_id,
        tecrmiEnabled,
        is_fleet_car,
        is_retail_car,
        setResults,
        setSearching,
      );
    },
    [request_id, tecrmiEnabled, is_fleet_car, is_retail_car],
  );

  const hasChanges = () => {
    return (
      !_.isEmpty(selected.components) ||
      !_.isEmpty(selected.codes) ||
      !_.isEmpty(selected.works) ||
      !_.isEmpty(selected.extras)
    );
  };

  const onClose = () => {
    if (hasChanges()) {
      setShowCancelAddDialog(true);
    } else {
      navigateBack();
    }
  };

  const onAdd = useCallback(async () => {
    if (!hasChanges()) {
      navigateBack();
      return;
    }

    dispatch(setAppIsLoading());

    try {
      const request_components = selected.components.map((name) => {
        const component = components.find((c) => c.component_name === name);

        return {
          ...(component ?? {}),
          name,
        };
      });

      const tasks = {};
      Object.values(selected.works).reduce((acc, item) => {
        acc[item.id ?? item.task_id ?? item.work_id] = {
          ...item,
          id: item.id ?? item.task_id,
          description: item.work_text ?? item.description,
          rob_code: customCodes[item.work_id]?.code ?? item.rob_code,
          rob_description:
            customCodes[item.work_id]?.description ?? item.rob_description,
        };
        return acc;
      }, tasks);

      /// TecRMI tasks have precedence over rob tasks.
      Object.values(selected.codes)
        .filter(
          (item) =>
            item.is_main_task ||
            !Object.values(tasks)
              .map((x) => x.rob_code)
              .includes(item.rob_code),
        )
        .reduce((acc, item) => {
          acc[item.id ?? item.task_id ?? item.rob_code] = {
            ...item,
            id: item.id ?? item.task_id,
            description: item.item ?? item.description,
            work_id: null,
          };
          return acc;
        }, tasks);

      Object.values(selected.extras).reduce((acc, item) => {
        const work_text =
          item.work_text ?? `${item.description}${item.qualifier ?? ''}`;

        acc[item.id ?? item.task_id ?? item.rob_code] = {
          ...item,
          id: item.id ?? item.task_id,
          type: 'MAINTENANCE_EXTRA',
          rob_code: customCodes[item.work_id]?.code ?? item.rob_code,
          rob_description:
            customCodes[item.work_id]?.description ?? item.rob_description,
          description: work_text,
          work_text,
        };
        return acc;
      }, tasks);

      await serviceRequestApi.add_tasks(request_id, {
        components: request_components,
        tasks: Object.values(tasks),
      });

      navigateBack();
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setAppDoneLoading());
    }
  }, [components, customCodes, dispatch, request_id, selected, navigateBack]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton backImage={() => <CloseIcon />} onPress={onClose} />
      ),
      headerStatusBarHeight: 0,
      headerTitleAlign: 'center',
      headerTitle: () => <Text>Item toevoegen</Text>,
    });
  }, [navigation, onClose]);

  useEffect(() => {
    const fetch = async () => {
      const {result} = await serviceRequestApi.get(request_id, dispatch);
      if (
        isLessorStatus(result[0].rob?.status) ||
        result[0].status === STATUS_CODES.Done ||
        result[0].status === STATUS_CODES.Invoiced ||
        result[0].status === STATUS_CODES.Archived
      ) {
        navigateBack();
        return;
      }

      setDatetime(result[0].datetime);
      setMileage(result[0].mileage);
      setComponents(result[0].components);
      setRob(result[0].rob);
      setTecRMIEnabled(result[0].tecrmi_enabled);

      setExisting({
        components: result[0].components
          .filter((item) =>
            Object.values(SERVICE_TYPE_COMPONENTS).includes(
              item.component_name,
            ),
          )
          .map((item) => item.name),
        codes: result[0].tasks
          .filter(
            (task) =>
              task.rob_code &&
              !['SLA_TASK_DURATION', 'SLA_TASK_AMOUNT'].includes(task.type),
          )
          .reduce((acc, task) => {
            acc[task.rob_code] = task;
            return acc;
          }, {}),
        works: result[0].tasks
          .filter(
            (task) =>
              task.reference !== null && task.type !== 'MAINTENANCE_EXTRA',
          )
          .reduce((acc, task) => {
            acc[task.reference] = {
              ...task,
              work_id: task.reference,
            };
            return acc;
          }, {}),
        extras: result[0].tasks
          .filter((task) => task.type === 'MAINTENANCE_EXTRA')
          .reduce((acc, task) => {
            acc[parseInt(task.reference)] = {
              ...task,
              work_id: parseInt(task.reference),
              task_id: task.id,
            };

            return acc;
          }, {}),
      });

      const {result: sla_codes} = await serviceRequestApi.rob_codes(request_id);
      setSlaCodes(sla_codes.filter((item) => _.isNumber(item.amount)));

      const {result: sla} = await serviceRequestApi.sla(request_id);
      setSlaKpis(sla.kpis);

      let contract;

      if (result[0].request_type === REQUEST_TYPES.SERVICE_REQUEST) {
        setIsRetailCar(true);
      } else if (result[0].request_type === REQUEST_TYPES.FLEET_REQUEST) {
        const {result: fleet_contract} = await fleetApi.contract(
          result[0].fleet_contract_id,
        );

        contract = fleet_contract.contract;
        setIsFleetCar(true);
      } else {
        const {result: most_used_codes} =
          await serviceRequestApi.most_used_rob_codes(request_id);
        setMostUsedCodes(most_used_codes);

        ({result: contract} = await robApi.contract(
          result[0].license,
          request_id,
        ));
      }
      setContract(contract);

      if (contract?.permissions.tecrmi) {
        // TecRMI costs exemption.
        setTecRMIEnabled(true);
      }

      const car = await carApi.fetch(result[0].license);
      setCar(car);

      setLoading(false);
    };

    setLoading(true);
    fetch();
  }, [request_id, navigation, dispatch, navigateBack]);

  useEffect(() => {
    if (loading) {
      dispatch(setAppIsLoading());
    } else {
      dispatch(setAppDoneLoading());
    }
  }, [loading, dispatch]);

  useEffect(() => {
    const fetch = async () => {
      if (
        [...selected.components, ...existing.components].includes(
          SERVICE_TYPE_COMPONENTS[SERVICE_TYPE_CODES.Maintenance],
        )
      ) {
        if (suggestions === null) {
          const {result} = await serviceRequestApi.suggestions(request_id);

          setSuggestions(result ?? []);
        }
      } else {
        setSuggestions(null);
      }
    };
    fetch();
  }, [request_id, selected, existing, suggestions]);

  const onCheckWork = (item, exclusive_set) => {
    let changes = {
      [item.work_id]: !selected.works[item.work_id] ? item : null,
    };

    if (exclusive_set) {
      for (const work_id of exclusive_set) {
        if (work_id !== item.work_id) {
          changes[work_id] = null;
        }
      }
    }

    setSelected({
      ...selected,
      works: _.pickBy({
        ...selected.works,
        ...changes,
      }),
    });
  };

  const onCheck = (task) => {
    setSelected({
      ...selected,
      codes: _.pickBy({
        ...selected.codes,
        [task.rob_code]: !selected.codes[task.rob_code] ? task : null,
      }),
    });
  };

  const onCheckComponent = (name, type) => {
    let components = selected.components;

    if (type === SERVICE_TYPE_CODES.Maintenance) {
      setTecRMIEnabled(true);

      if (
        ![...components, ...existing.components].includes(
          SERVICE_TYPE_COMPONENTS[SERVICE_TYPE_CODES.Inspection],
        ) &&
        INSPECTION_EXTENDED_LM.includes(contract?.lessor?.name) &&
        moment().isAfter(
          moment(contract.vehicle?.maintenance?.nextPeriodicCheck).add(
            -(INSPECTION_EXTENDED_MONTHS[contract.lessor.name] ?? 8),
            'months',
          ),
        )
      ) {
        components = [
          ...components,
          SERVICE_TYPE_COMPONENTS[SERVICE_TYPE_CODES.Inspection],
        ];
      }
    }

    if (components.includes(name)) {
      components = components.filter((item) => item !== name);
    } else {
      components = [...components, name];
    }

    setSelected({
      ...selected,
      components,
    });
  };

  const contract_ends =
    contract &&
    contract.leaseContract &&
    moment(contract.leaseContract.endDate).isBefore(moment().add(3, 'months'));

  return (
    <View style={sy.mainView}>
      {!loading && (
        <>
          <View style={[sy['flex-row'], sy['items-center'], sy['p-4']]}>
            <View
              style={[
                sy['flex-row'],
                sy['flex-1'],
                sy['p-2'],
                sy['items-center'],
                {
                  borderRadius: 10,
                  backgroundColor: '#EBEBEB',
                },
              ]}>
              <SearchIcon
                fill={
                  searchTextRef.current?.value.length ? '#4A4A49' : '#828282'
                }
                width={18}
                height={18}
              />
              <TextInput
                ref={(ref) => {
                  searchTextRef.current = ref;
                }}
                style={[
                  sy.regular,
                  sy['flex-1'],
                  {
                    color:
                      searchTextRef.current?.value.length > 0
                        ? '#4A4A49'
                        : '#828282',
                    marginLeft: 16,
                  },
                ]}
                placeholder={'Zoeken'}
                onChangeText={(value) => {
                  onPrefixChange(value);
                  setHighlightSearchText(value);
                }}
              />
            </View>
            <Animated.View
              style={[
                sy['mt-2'],
                {
                  height: 29,
                  paddingLeft: searchTextRef.current?.value.length ? 8 : 0,
                  maxWidth: cancelAnim,
                  justifyContent: 'center',
                },
              ]}>
              <Pressable
                onPress={() => {
                  onPrefixChange('');
                  setResults([]);
                  setHighlightSearchText('');
                  searchTextRef.current.clear();
                }}>
                <Text
                  style={[
                    sy.regular,
                    {
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    },
                  ]}>
                  Annuleren
                </Text>
              </Pressable>
            </Animated.View>
          </View>

          <ScrollView style={sy['bg-gray95']}>
            {!search && (
              <>
                <Grouping
                  header={
                    <View
                      style={[sy['flex-row'], sy['items-center'], sy['gap-4']]}>
                      <>Aanvraag</>
                      {contract_ends && (
                        <Pressable
                          onPress={() => setShowContractEndsDialog(true)}>
                          <WarningIcon fill="#972727" />
                        </Pressable>
                      )}
                    </View>
                  }
                  initial={{expand: true}}>
                  {Object.keys(SERVICE_TYPE_CODES)
                    .filter((item) => item !== SERVICE_TYPE_CODES.Repairs)
                    .map((type) => (
                      <View key={type}>
                        <ServiceComponent
                          request_id={request_id}
                          type={type}
                          checked={
                            selected.components.includes(
                              SERVICE_TYPE_COMPONENTS[type],
                            ) ||
                            existing.components.includes(
                              SERVICE_TYPE_COMPONENTS[type],
                            )
                          }
                          datetime={datetime}
                          is_retail_car={is_retail_car}
                          contract={contract}
                          tecrmi_enabled={tecrmiEnabled}
                          components={components}
                          onCheck={onCheckComponent}
                          onRefresh={async () => {
                            const {result} = await serviceRequestApi.get(
                              request_id,
                              dispatch,
                            );

                            setTecRMIEnabled(result[0].tecrmi_enabled);
                          }}
                        />
                      </View>
                    ))}
                </Grouping>

                {suggestions?.length > 0 && (
                  <Grouping
                    header="Extra services bij onderhoud"
                    initial={{expand: true}}>
                    <View
                      style={[sy['flex-row'], sy['items-center'], sy['p-4']]}>
                      <View style={[sy['pr-4']]}>
                        <DirectionsCarIcon />
                      </View>
                      <View style={[sy['flex-1'], sy['flex-row']]}>
                        {_.isNumber(mileage) && (
                          <Text style={[sy.mediumBold]}>
                            {Format.number(mileage, 0, true)}
                            &nbsp;km&nbsp;&middot;&nbsp;
                          </Text>
                        )}
                        <Text style={[sy.mediumBold]}>
                          {Format.number(
                            moment().diff(car.entry_date, 'months') / 12,
                            1,
                            true,
                            {
                              minimumFractionDigits: 0,
                            },
                          )}
                          &nbsp;jaar
                        </Text>
                      </View>
                    </View>
                    <Divider style={sy['bg-gray95']} />
                    {suggestions
                      .filter(
                        (item) =>
                          item.is_maintenance_extra &&
                          (showAllTasks ||
                            item.children.every(
                              (child) =>
                                !slaKpis?.[
                                  customCodes?.[child.work_id]?.code ??
                                    child.rob_code
                                ] && !child.kpi,
                            )),
                      )
                      .map((item) =>
                        item.children.map((child) => (
                          <React.Fragment key={child.work_id}>
                            <View
                              key={item.id}
                              style={[sy['p-4'], sy['flex-row']]}>
                              <RadioButton
                                checked={
                                  !!existing.extras[child.work_id] ||
                                  !!selected.extras[child.work_id]
                                }
                                disabled={item.children.some(
                                  (x) => !!existing.extras[x.work_id],
                                )}
                                onPress={() => {
                                  let changes = {
                                    [child.work_id]: !selected.extras[
                                      child.work_id
                                    ]
                                      ? {
                                          ...child,
                                          description: item.description,
                                        }
                                      : null,
                                  };

                                  for (const work of item.children) {
                                    if (work.work_id !== child.work_id) {
                                      changes[work.work_id] = null;
                                    }
                                  }

                                  setSelected({
                                    ...selected,
                                    extras: _.pickBy({
                                      ...selected.extras,
                                      ...changes,
                                    }),
                                  });
                                }}
                              />
                              <View style={[sy['flex-1'], sy['pl-4']]}>
                                <Text>
                                  {item.description},&nbsp;{child.qualifier}
                                </Text>
                                {!is_fleet_car &&
                                  !is_retail_car &&
                                  child.rob_code && (
                                    <Text
                                      style={[sy.small, sy['text-lightgray']]}>
                                      {child.rob_code}&nbsp;&middot;&nbsp;
                                      {child.rob_description}
                                    </Text>
                                  )}
                              </View>
                              <View>
                                <Text>
                                  {Format.number(child.work_time, 2)}&nbsp;u
                                </Text>
                                <View style={[sy['flex-1']]}>
                                  <KpiWarning
                                    style={[
                                      sy['flex-1'],
                                      sy['items-end'],
                                      sy['justify-end'],
                                    ]}
                                    kpi={
                                      slaKpis?.[
                                        customCodes?.[child.work_id]?.code ??
                                          child.rob_code
                                      ] ?? child.kpi
                                    }
                                  />
                                </View>
                              </View>
                            </View>
                            <Divider style={sy['bg-gray95']} />
                          </React.Fragment>
                        )),
                      )}
                    {suggestions
                      .filter(
                        (item) =>
                          !item.is_maintenance_extra &&
                          (showAllTasks ||
                            item.children.every(
                              (child) =>
                                !slaKpis?.[
                                  customCodes?.[child.work_id]?.code ??
                                    child.rob_code
                                ] && !child.kpi,
                            )),
                      )
                      .map((item) => (
                        <View
                          key={item.id}
                          style={[
                            sy['border-b'],
                            sy['border-lightgray'],
                            sy['py-4'],
                          ]}>
                          <View style={[sy['flex-row']]}>
                            <Checkbox
                              containerStyle={[sy['px-4']]}
                              style={[sy['justify-start']]}
                              checked={item.children.some(
                                (child) =>
                                  !!existing.works[child.work_id] ||
                                  !!selected.works[child.work_id],
                              )}
                              disabled={item.children.some(
                                (child) => !!existing.works[child.work_id],
                              )}
                              disabledTimeout={0}
                              onPress={() =>
                                onCheckWork(
                                  {
                                    ...(item.children.find(
                                      (child) =>
                                        !!selected.works[child.work_id],
                                    ) ?? item.children[0]),
                                    work_text: `${item.description} ${item.action}`,
                                  },
                                  item.children.map((item) => item.work_id),
                                )
                              }
                            />
                            <View style={[sy['flex-1']]}>
                              <Text>
                                {item.description}
                                {!!item.action &&
                                  item.action !== 'uitvoeren' && (
                                    <>
                                      <Text>&nbsp;{item.action}</Text>
                                    </>
                                  )}
                              </Text>
                              {!item.add_text &&
                                item.children.length === 1 &&
                                item.children[0].qualifier !== 'Standaard' && (
                                  <Text
                                    style={[sy.small, sy['text-lightgray']]}>
                                    {item.children[0].qualifier}
                                  </Text>
                                )}
                              {!!item.add_text && (
                                <Text style={[sy.small, sy['text-lightgray']]}>
                                  {item.add_text}
                                </Text>
                              )}
                              {!is_fleet_car &&
                                !is_retail_car &&
                                item.children.length === 1 && (
                                  <ChangeRobCodeLink
                                    rob={rob}
                                    task_id={item.children[0].task_id}
                                    rob_code={item.children[0].rob_code}
                                    rob_description={
                                      item.children[0].rob_description
                                    }
                                    work_id={item.children[0].work_id}
                                    custom_codes={customCodes}
                                    onPress={(work_id, rob_code) => {
                                      setShowRobCodeActionsheet({
                                        rob_code,
                                        work_id,
                                      });
                                    }}
                                  />
                                )}
                            </View>
                            <View style={[sy['px-4']]}>
                              {item.children.length === 1 && (
                                <>
                                  <Text>
                                    {Format.number(
                                      item.children[0].work_time,
                                      2,
                                    )}
                                    &nbsp;u
                                  </Text>
                                  <View style={[sy['flex-1']]}>
                                    <KpiWarning
                                      style={[
                                        sy['flex-1'],
                                        sy['items-end'],
                                        sy['justify-end'],
                                      ]}
                                      kpi={
                                        slaKpis?.[
                                          customCodes?.[
                                            item.children[0].work_id
                                          ]?.code ?? item.children[0].rob_code
                                        ] ?? item.children[0].kpi
                                      }
                                    />
                                  </View>
                                </>
                              )}
                            </View>
                          </View>
                          {item.children.length > 1 &&
                            item.children.map((child) => (
                              <View
                                key={child.work_id}
                                style={[
                                  sy['flex-row'],
                                  sy['justify-between'],
                                  sy['p-2'],
                                  sy['pl-8'],
                                  sy['pr-4'],
                                ]}>
                                <View style={[sy['flex-row'], sy['flex-1']]}>
                                  <RadioButton
                                    checked={
                                      !!existing.works[child.work_id] ||
                                      !!selected.works[child.work_id]
                                    }
                                    disabled={item.children.some(
                                      (child) =>
                                        !!existing.works[child.work_id],
                                    )}
                                    onPress={() => {
                                      onCheckWork(
                                        {
                                          ...child,
                                          work_text: `${item.description} ${item.action}`,
                                        },
                                        item.children.map(
                                          (item) => item.work_id,
                                        ),
                                      );
                                    }}
                                  />
                                  <View style={[sy['pl-4'], sy['flex-1']]}>
                                    <Text style={[sy.truncate]}>
                                      {child.qualifier}
                                    </Text>

                                    {!is_fleet_car && !is_retail_car && (
                                      <ChangeRobCodeLink
                                        rob={rob}
                                        task_id={child.task_id}
                                        rob_code={child.rob_code}
                                        rob_description={child.rob_description}
                                        work_id={child.work_id}
                                        custom_codes={customCodes}
                                        onPress={(work_id, rob_code) => {
                                          setShowRobCodeActionsheet({
                                            rob_code,
                                            work_id,
                                          });
                                        }}
                                      />
                                    )}
                                  </View>
                                </View>
                                <View>
                                  <Text>
                                    {Format.number(child.work_time, 2)}&nbsp;u
                                  </Text>
                                  <View style={[sy['flex-1']]}>
                                    <KpiWarning
                                      style={[
                                        sy['flex-1'],
                                        sy['items-end'],
                                        sy['justify-end'],
                                      ]}
                                      kpi={
                                        slaKpis?.[
                                          customCodes?.[child.work_id]?.code ??
                                            child.rob_code
                                        ] ?? child.kpi
                                      }
                                    />
                                  </View>
                                </View>
                              </View>
                            ))}
                        </View>
                      ))}
                    {!showAllTasks &&
                      suggestions.some((item) =>
                        item.children.some(
                          (child) =>
                            slaKpis?.[
                              customCodes?.[child.work_id]?.code ??
                                child.rob_code
                            ] ?? child.kpi,
                        ),
                      ) && (
                        <Pressable
                          style={[sy['p-4'], sy['flex-row']]}
                          onPress={() => setShowAllTasks(true)}>
                          <View style={[sy['pr-8']]}>
                            <UnfoldMoreIcon fill="#231fda" />
                          </View>
                          <Text>Meer services tonen</Text>
                        </Pressable>
                      )}
                  </Grouping>
                )}

                {mostUsedCodes.length > 0 && (
                  <Grouping
                    header="ROB activiteiten (meest gekozen reparatie)"
                    initial={{expand: true}}>
                    {mostUsedCodes.map((item) => (
                      <View
                        key={item.rob_code}
                        style={[
                          sy['border-b'],
                          sy['border-lightgray'],
                          sy['py-4'],
                        ]}>
                        <View style={[sy['flex-row']]}>
                          <Checkbox
                            containerStyle={[sy['px-4']]}
                            style={[sy['justify-start']]}
                            checked={
                              !!existing.codes[item.rob_code] ||
                              !!selected.codes[item.rob_code]
                            }
                            disabled={!!existing.codes[item.rob_code]}
                            disabledTimeout={0}
                            onPress={() => onCheck(item)}
                          />
                          <View style={[sy['flex-1']]}>
                            <Text>
                              {item.rob_code}&nbsp;&middot;&nbsp;
                              {item.description}
                            </Text>
                            {item.sla && (
                              <Text style={[sy.small, sy['text-lightgray']]}>
                                SLA&nbsp;&middot;&nbsp;{item.sla?.item}
                              </Text>
                            )}
                          </View>
                          {item.sla && (
                            <View style={[sy['px-4']]}>
                              <Text>{Format.price(item.sla?.amount, 2)}</Text>
                            </View>
                          )}
                        </View>
                      </View>
                    ))}
                  </Grouping>
                )}
              </>
            )}
            {searching && (
              <>
                <Divider />
                <ActivityIndicator size="large" color="#231fda" />
              </>
            )}
            {search && !searching && (
              <>
                <Divider />
                {Object.keys(results).map((source, index) => (
                  <React.Fragment key={source}>
                    <Grouping
                      header={
                        source === SOURCES.Rob
                          ? 'ROB-Net activiteiten'
                          : source === SOURCES.TecRMI
                          ? 'TecRMI reparatie'
                          : source
                      }
                      initial={{expand: true}}
                      options={{topMost: index === 0}}>
                      {results[source].length === 0 && (
                        <View
                          style={[
                            sy['p-4'],
                            sy['items-center'],
                            sy['justify-center'],
                          ]}>
                          <Text>Geen zoekresultaten beschikbaar...</Text>
                          {source === SOURCES.TecRMI && !tecrmiEnabled && (
                            <Link
                              onPress={async () => {
                                try {
                                  await new Promise((resolve, reject) => {
                                    dispatch(
                                      showTecrmiDialog({
                                        resolve,
                                        reject,
                                      }),
                                    );
                                  });

                                  await serviceRequestApi.enable_tecrmi(
                                    request_id,
                                  );
                                  setTecRMIEnabled(true);
                                } catch {
                                  return;
                                }
                              }}
                              textStyle={{color: '#2907E3'}}>
                              Service info ophalen
                            </Link>
                          )}
                        </View>
                      )}

                      {results[source].map((result, index, arr) => {
                        let checked = false;
                        let disabled = false;
                        let sla_item;
                        switch (result.source) {
                          case SOURCES.Rob:
                          case SOURCES.SlaTasks:
                            sla_item = slaCodes.find(
                              (item) => item.rob_code === result.rob_code,
                            );
                            break;
                        }

                        switch (result.source) {
                          case SOURCES.Rob:
                            checked = !!selected.codes[result.rob_code];
                            disabled = !!existing.codes[result.rob_code];
                            sla_item = slaCodes.find(
                              (item) => item.rob_code === result.rob_code,
                            );
                            break;
                          case SOURCES.SlaTasks:
                          case SOURCES.TecRMI:
                            checked = !!selected.works[result.work_id];
                            disabled = !!existing.works[result.work_id];
                            break;
                          default:
                            break;
                        }

                        return (
                          <React.Fragment
                            key={
                              result.id ?? result.work_id ?? result.rob_code
                            }>
                            <View
                              style={[sy['flex-row'], sy['p-4'], sy['gap-8']]}>
                              <Checkbox
                                style={[
                                  sy['justify-start'],
                                  {height: 'auto', width: 'auto'},
                                ]}
                                checked={disabled || checked}
                                disabled={disabled}
                                disabledTimeout={0}
                                onPress={async () => {
                                  switch (result.source) {
                                    case SOURCES.Rob:
                                      onCheck(result);
                                      break;
                                    case SOURCES.TecRMI:
                                      const {suggestion, ...rest} = result;
                                      onCheckWork({
                                        ...rest,
                                        rob_code: suggestion.code,
                                        rob_description: suggestion.description,
                                      });
                                      break;
                                    case SOURCES.SlaTasks:
                                      onCheckWork(result);
                                      break;
                                    default:
                                      break;
                                  }
                                }}
                              />
                              <View
                                style={[
                                  sy['flex-row'],
                                  sy['flex-1'],
                                  sy['justify-between'],
                                  sy['gap-4'],
                                ]}>
                                <View style={[sy['flex-1']]}>
                                  <View style={{display: 'inline'}}>
                                    {result.source === SOURCES.Rob && (
                                      <>
                                        <HighlightText
                                          text={result.rob_code}
                                          highlight={highlighSearchText}
                                        />
                                        <Text>&nbsp;&middot;&nbsp;</Text>
                                      </>
                                    )}
                                    <HighlightText
                                      text={
                                        result.work_text ?? result.description
                                      }
                                      highlight={highlighSearchText}
                                    />
                                  </View>
                                  {[SOURCES.Rob, SOURCES.SlaTasks].includes(
                                    result.source,
                                  ) &&
                                    sla_item && (
                                      <Text
                                        style={[
                                          sy.small,
                                          sy['text-lightgray'],
                                        ]}>
                                        SLA&nbsp;&middot;&nbsp;{sla_item.item}
                                      </Text>
                                    )}
                                  {result.source === SOURCES.TecRMI && (
                                    <>
                                      {result.qualifier &&
                                        result.qualifier !== 'Standaard' && (
                                          <Text
                                            style={[sy.small, sy['text-gray']]}>
                                            {result.qualifier}
                                          </Text>
                                        )}
                                      {result.add_text &&
                                        result.add_text !== 'Standaard' && (
                                          <Text
                                            style={[sy.small, sy['text-gray']]}>
                                            {result.add_text}
                                          </Text>
                                        )}
                                      {!is_fleet_car &&
                                        !is_retail_car &&
                                        result.suggestion && (
                                          <ChangeRobCodeLink
                                            rob={rob}
                                            task_id={result.task_id}
                                            rob_code={result.suggestion.code}
                                            rob_description={
                                              result.suggestion.description
                                            }
                                            work_id={result.work_id}
                                            custom_codes={customCodes}
                                            onPress={(work_id, rob_code) => {
                                              setShowRobCodeActionsheet({
                                                rob_code,
                                                work_id,
                                              });
                                            }}
                                          />
                                        )}
                                    </>
                                  )}
                                </View>
                                {result.source === SOURCES.Rob && (
                                  <View
                                    style={[
                                      sy['flex-row'],
                                      sy['items-start'],
                                      sy['gap-2'],
                                    ]}>
                                    {sla_item && (
                                      <Text style={sy.smallRegular}>
                                        {Format.price(sla_item.amount, 2)}
                                      </Text>
                                    )}
                                    <KpiWarning
                                      kpi={slaKpis?.[result.rob_code]}
                                    />
                                  </View>
                                )}
                                {result.source === SOURCES.SlaTasks && (
                                  <View>
                                    {sla_item ? (
                                      <Text style={sy.smallRegular}>
                                        {Format.price(sla_item.amount, 2)}
                                      </Text>
                                    ) : result.type === 'SLA_TASK_DURATION' ? (
                                      <Text>
                                        {Format.number(result.work_time, 2)}
                                        &nbsp;u
                                      </Text>
                                    ) : (
                                      <Text>{Format.price(result.amount)}</Text>
                                    )}
                                    <View style={[sy['flex-1']]}>
                                      <KpiWarning
                                        style={[
                                          sy['flex-1'],
                                          sy['items-end'],
                                          sy['justify-end'],
                                        ]}
                                        kpi={slaKpis?.[result.condition?.code]}
                                      />
                                    </View>
                                  </View>
                                )}
                                {result.source === SOURCES.TecRMI &&
                                  _.isNumber(result.work_time) && (
                                    <View>
                                      <Text>
                                        {Format.number(result.work_time, 2)}
                                        &nbsp;u
                                      </Text>
                                      <View style={[sy['flex-1']]}>
                                        <KpiWarning
                                          style={[
                                            sy['flex-1'],
                                            sy['items-end'],
                                            sy['justify-end'],
                                          ]}
                                          kpi={
                                            slaKpis?.[
                                              customCodes[result.work_id]
                                                ?.code ?? result.suggestion.code
                                            ]
                                          }
                                        />
                                      </View>
                                    </View>
                                  )}
                              </View>
                            </View>
                            {index < arr.length - 1 && (
                              <Divider style={[sy['bg-gray95'], sy['ml-18']]} />
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Grouping>
                  </React.Fragment>
                ))}
              </>
            )}
          </ScrollView>
          <Divider />
          <View style={[sy['p-4'], sy['flex-row'], sy['justify-end']]}>
            <Changes
              selected={selected}
              hasChanges={hasChanges}
              onAdd={onAdd}
            />
          </View>
          {showRobCodeActionsheet && (
            <RobCodeActionsheet
              request_id={request_id}
              license={license}
              codes={[showRobCodeActionsheet.rob_code].filter(Boolean)}
              rob_code={showRobCodeActionsheet.rob_code}
              onDismiss={() => setShowRobCodeActionsheet(null)}
              onChange={(code, description) => {
                setCustomCodes({
                  ...customCodes,
                  [showRobCodeActionsheet.work_id]: {
                    code,
                    description,
                  },
                });
                setShowRobCodeActionsheet(null);
              }}
              options={{maintenance_codes: true}}
            />
          )}
          {showContractEndsDialog && (
            <Dialog
              visible={true}
              title="Let op einde contract"
              onDismiss={() => setShowContractEndsDialog(false)}
              buttons={[
                {text: 'OK', onPress: () => setShowContractEndsDialog(false)},
              ]}>
              <View style={[sy['flex-row'], sy['items-start'], sy['py-4']]}>
                <WarningIcon fill="#972727" />
                <Text style={[sy['ml-4']]}>
                  Einddatum:&nbsp;
                  {moment(contract.leaseContract.endDate).format('D-M-YYYY')}
                  &nbsp;(
                  {Format.number(
                    contract.leaseContract.maximumMileage,
                    0,
                    true,
                  )}
                  )
                </Text>
              </View>
            </Dialog>
          )}
          <Dialog
            visible={showCancelAddDialog}
            title="Selectie niet toevoegen?"
            onDismiss={() => setShowCancelAddDialog(false)}
            buttons={[
              {
                text: 'Annuleren',
                onPress: () => setShowCancelAddDialog(false),
              },
              {
                text: 'Niet toevoegen',
                onPress: navigation.goBack,
              },
            ]}>
            <View style={[sy['py-4']]}>
              <Text>
                Weet je zeker dat je dit venster wil sluiten zonder de selectie
                toe te voegen?
              </Text>
            </View>
          </Dialog>
        </>
      )}
    </View>
  );
};

export default withProtectedScreen(Index);
